import styled from 'styled-components';

import BaseText from './styled';
// Deprecated. Use MUI Typography instead

const createThemedStyle = ({ theme, size }) => {

  const sizes = {
    regular: {
      fontSize: theme.fontSizes.font_10,
      lineHeight: theme.lineHeight.line_12,
      letterSpacing: 0.4
    }
  };

  return sizes[size];
};

const Overline = styled(BaseText)`
  ${createThemedStyle};
`;

Overline.defaultProps = {
  size: 'regular'
};

export default Overline;
// Deprecated. Use MUI Typography instead
