export default {
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  PLANS: '/plans',
  SELECTPLAN: '/select-plan',
  CHECKOUT: '/checkout',
  SUPPORT: '/support',
  PROFILE: '/profile',
  PROFILE_PAYMENT: '/profile/payment',
  NOT_FOUND: '/notfound',
  FORBIDDEN: '/forbidden',
  UNAUTHORIZED: '/unauthorized',
  BAD_REQUEST: '/badrequest',
  SERVER_ERROR: '/servererror',
  BACKEND_UNAVAILABLE: '/backendunavailable',
  SETUP: '/setup'
};
