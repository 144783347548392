import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const DialogButtonActions = ({ buttonsConfig, handleClose, isProcessing, onlyOneButton, actions }) => {
  const shouldReturnButton = (button) => {
    const twoButtons = !isProcessing && !onlyOneButton;
    const oneButton = !isProcessing && onlyOneButton && button?.type !== 'CANCEL';
    return twoButtons || oneButton;
  };

  if (actions) {
    return typeof actions === 'function' ? actions({ handleClose }) : actions;
  }

  return buttonsConfig.map(
    (button) =>
      shouldReturnButton(button) && (
        <Button
          variant={button.color && 'contained'}
          color={button.color}
          onClick={
            button.close
              ? () => {
                  handleClose();
                  button.handleClick && button.handleClick();
                }
              : () => {
                  button.handleClick({ handleClose });
                }
          }
        >
          {button.title}
        </Button>
      )
  );
};

DialogButtonActions.propTypes = {
  buttonsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['CANCEL', 'CONFIRM']),
      handleClick: PropTypes.func,
      close: PropTypes.bool,
      color: PropTypes.oneOf(['primary', 'secondary'])
    })
  ),
  handleClose: PropTypes.func,
  actions: PropTypes.element,
  isProcessing: PropTypes.bool,
  onlyOneButton: PropTypes.bool
};

export default DialogButtonActions;
