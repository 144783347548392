import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '../../theme/theme';

const StyleFlag = styled.div`
  img {
    width: ${theme.size.swell(3)};
    height: ${theme.size.swell(3)};
  }
`;

const Flag = (props) => {
  const { code } = props;
  return (
    <StyleFlag>
      <img src={`/assets/flags/${code.toLowerCase()}.png`} alt={code} />
    </StyleFlag>
  );
};

Flag.defaultProps = {
  code: 'GB'
};

Flag.propTypes = {
  /** ISO 3166-1 alpha-2 code - [link](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements) */
  code: PropTypes.string
};

export default Flag;
