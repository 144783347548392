export const types = {
  ADD_PRODUCT: 'shopping/ADD_PRODUCT',
  ADD_CREDIT_CARD: 'shopping/ADD_CREDIT_CARD',
  CLEAR_CART: 'shopping/CLEAR_CART',
  CLEAR_CREDIT_CARD: 'shopping/CLEAR_CREDIT_CARD'
};

export const addToShoppingCart = (productInfo) => ({
  type: types.ADD_PRODUCT,
  payload: productInfo
});

export const clearShoppingCart = () => ({
  type: types.CLEAR_CART,
});

export const addCreditCard = ({ number, cvv, expireDate, name, valid }) => ({
  type: types.ADD_CREDIT_CARD,
  payload: { number, cvv, expireDate, name, valid }
});

export const clearCreditCard = () => ({
  type: types.CLEAR_CREDIT_CARD
});
