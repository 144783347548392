import React from 'react';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  underline: {
    position: 'relative',
    fontWeight: 'bold',
    overflow: 'hidden',
    marginBottom: theme.size.swell(1),
    width: (props) => (props.width ? props.width : '100%'),
    color: theme.colors.black,
    fontSize: '20px',
    lineHeight: 1.4,
    [theme.breakpoints.down('xs')]: {
      textTransform: 'uppercase'
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      width: '100%',
      height: (props) => (props.extraStyle === 'underline' ? '2px' : '0'),
      left: 'auto',
      backgroundColor: theme.colors.black,
      marginLeft: '.7rem',
      borderBottom: (props) => (props.extraStyle === 'underline' ? '1px solid rgba(255, 255, 255, 0.85)' : '')
    }
  },
  fullWidth: {
    width: '100%'
  }
}));

const TitleComponent = (props) => {
  const classes = useStyles(props);

  return (
    <Box mt={props.mt ? props.mt : 0} mb={props.mb ? props.mb : 0} className={classes.fullWidth}>
      <Typography variant={props.variant} component={props.displayAs} className={classes.underline}>
        {props.title}
      </Typography>
    </Box>
  );
};

TitleComponent.propTypes = {
  title: string,
  displayAs: string,
  variant: string,
  extraStyle: string,
  width: string
};

TitleComponent.defaultProps = {
  title: 'title',
  displayAs: 'h4',
  extraStyle: 'underline',
  width: '100%'
};

export default TitleComponent;
