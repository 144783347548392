import { useSelector } from 'react-redux';

import { getUserProfile } from '../redux/selectors';
import { useAuth } from '../auth';

const useCustomerNaming = () => {
  const profile = useSelector(getUserProfile);
  const { user } = useAuth();

  let name = '';
  let email = '';
  let first_name = '';
  let last_name = '';

  const registeredUser = profile && profile.first_name && profile.email;
  const firstTimeUser = user && user.firstName && user.email;
  const nameDiferentFromEmail = profile.first_name !== profile.email && profile.last_name !== profile.email;

  if (registeredUser && nameDiferentFromEmail) {
    name = `${profile.first_name} ${profile.last_name}`;
    email = profile.email;
    first_name = profile.first_name;
    last_name = profile.last_name;
  } else if (firstTimeUser) {
    name = `${user.firstName} ${user.lastName}`;
    email = user.email;
    first_name = user.firstName;
    last_name = user.lastName;
  }

  return { name, email, first_name, last_name };
};

export default useCustomerNaming;
