import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Image } from "..";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.contrastText,
    minHeight: theme.spacing(20),
    width: "100%",
    borderRadius: theme.size.borderRadiusCards,
    marginTop:theme.spacing(1),
    [theme.breakpoints.down('md')]:{
      marginTop:0,
    }
  },
  mask: {
    position: 'absolute',
    zIndex: 2,
    width: "100%",
    height: theme.spacing(12),
    background: 'linear-gradient(to bottom, rgba(13, 49, 63, 0.1), rgba(13, 49, 63, 0.85) 76%);',
    borderRadius: theme.size.borderRadiusCards
  },
  content: {
    width: "100%",
    position: 'absolute',
    zIndex: 3
  },
  wrap: {
    margin: '0px',
    padding: '0px',
    overflow: 'hidden',
    objectFit: "cover",
    height: theme.spacing(22.5),
    borderTopLeftRadius:theme.size.borderRadiusCards,
    borderTopRightRadius:theme.size.borderRadiusCards,
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('md')]:{
      borderRadius:theme.size.borderRadiusCards
    }
  },
  img: {
    width: "100%"
  }
}));

const ImageCard = (props) => {
  const classes = useStyles(props);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      className={classes.flex}
      width={props.width ? props.width : '100%'}
    >
      <Box className={classes.box}>
        <Box className={classes.wrap}>
          <Box className={classes.mask} />
          <Box className={classes.content}>
            {props.content}
          </Box>
          <Image
            fileName={props.imageFileName}
            alt={props.imageAlt}
            className={classes.img}
          />
        </Box>
      </Box>
    </Box>
  );
};

ImageCard.defaultProps = {
  // imageFileName: 'planEurope.png',
  // imageAlt: 'country',
  content: null
};

export default ImageCard;
