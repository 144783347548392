import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const anchorOrigin = { vertical: 'top', horizontal: 'center' };

function SnackbarContainer({ snacks = [], closeSnackbar }) {
  return snacks.map((snack) => {
    const customProps = { onClose: () => closeSnackbar(snack.id) };
    return (
      <Snackbar key={snack.id} anchorOrigin={anchorOrigin} open>
        {snack.children ? (
          React.cloneElement(snack.children, { ...customProps })
        ) : (
          <Alert severity={snack.severity} {...customProps}>
            {snack.message}
          </Alert>
        )}
      </Snackbar>
    );
  });
}

export default SnackbarContainer;
