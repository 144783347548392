import styled from 'styled-components';

import { theme } from '../../theme/theme';

export const Highlight = styled.div`
  width: ${theme.size.swell(20)};
  height: ${theme.size.swell(4)};
  margin: ${theme.size.swell(1)};
  border-radius: ${theme.size.borderRadius};
  background-color: ${theme.colors.blue1};
`;

export const ECard = styled.div`
  width: ${theme.size.swell(22)};
  margin: ${theme.size.swell(1)};
  border-radius: ${theme.size.borderRadiusCards};
  background-color: ${theme.colors.white};
  border: solid 4px ${props => props.selected ? theme.colors.blue4 : theme.colors.white};
  box-shadow: ${theme.shadows[2]};
`;

export const BaseCard = styled.div`
  border-radius: ${(props) => props.borderRadius}px;
  align-items: center;
  border-width: 1px;
  border-color: 'red';
  background-color: white;
  width: 100%;
`;

export const BaseVerticalCard = styled(BaseCard)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

BaseCard.defaultProps = {
  borderRadius: 8
};

export default BaseVerticalCard;
