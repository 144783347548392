import { env } from './utils/getEnv';

export default function gtmInit() {
  const headScript = (gtmId) =>
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

  const noScript = (gtmId) =>
    `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;

  const gtmId = env.REACT_APP_ENV !== 'production' ? 'GTM-TZSCMJJC' : 'GTM-PPHDHHW6';
  const noScriptElement = document.createElement('noscript');
  noScriptElement.innerHTML = noScript(gtmId);
  document.body.append(noScriptElement);

  const script = document.createElement('script');
  script.innerHTML = headScript(gtmId);
  document.head.append(script);
}
