import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { useAuth } from '../../auth/keycloakContext';
import MainLayout from '../../ui/layout/MainLayout';
import { AppLoading } from '../../ui/commons';
import routes from '../../constants/routes';

const RedirectToPath = (props) => (
  <Redirect
    to={{
      pathname: props.path,
      state: { from: props.location }
    }}
  />
);

const RenderApp = ({ isDetail, hideMenu, Component, ...rest }) =>
  isDetail ? (
    <>
      <Hidden smUp>
        <Component {...rest} />
      </Hidden>
      <Hidden only="xs">
        <MainLayout hideMenu={hideMenu}>
          <Component {...rest} />
        </MainLayout>
      </Hidden>
    </>
  ) : (
    <MainLayout hideMenu={hideMenu}>
      <Component {...rest} />
    </MainLayout>
  );

const userWasCreatedOnSalesForce = (user) => user.attributes && Array.isArray(user.attributes.u) && user.attributes.u[0];

export default function PrivateRoute({ component: Component, location, ...rest }) {
  const { keycloak, user } = useAuth();

  const from = (location.state && location.state.from) || '';

  const handleAuthentication = (props) => {
    if (keycloak.authenticated) {
      if (user) {
        return userWasCreatedOnSalesForce(user) ? (
          <RenderApp {...rest} Component={Component} />
        ) : (
          <RedirectToPath path="/profileForm" {...props} />
        );
      }
      return <AppLoading />;
    }

    let redirectToRegister = from === 'selectPlanPage';
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('action') === 'register') {
        redirectToRegister = true;
      }
    }

    if (redirectToRegister) {
      // TODO: commenting this until product definition
      // return <Redirect to={routes.REGISTER} />;
      return <Redirect to={{ pathname: routes.REGISTER, state: { from: location } }} />;
    }
    return <Redirect to={{ pathname: routes.LOGIN, state: { from: location } }} />;
  };

  return <Route {...rest} render={handleAuthentication} />;
}
