import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SnackbarContainer from './SnackbarContainer';
import { SnackbarContext } from './snackbarContext';

const SnackbarProvider = ({ children }) => {
  const [snacks, setSnacks] = useState([]);

  /**
   * Adds a new snackbar to the queue to be presented.
   * @param {string} message - text of the notification
   * @param {object} optionsoptions - additional options for the snackbar we want to enqueue.
   */
  const enqueueSnackbar = (message, options) => {
    const snack = { id: uuidv4(), message, ...options };
    setSnacks([...snacks, snack]);
  };

  const retryPaymentSnackbar = (options) => {
    const snack = { id: uuidv4(), ...options };
    setSnacks([snack]);
  };

  /**
   * Close snackbar with the given key
   * @param {uuidv4} key - id of the snackbar we want to hide
   */
  const closeSnackbar = (key) => {
    setSnacks(snacks.filter((item) => item.id !== key));
  };

  return (
    <SnackbarContext.Provider
      value={{
        snacks,
        enqueueSnackbar,
        retryPaymentSnackbar,
        closeSnackbar
      }}
    >
      {children}
      <SnackbarContainer snacks={snacks} closeSnackbar={closeSnackbar} />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
