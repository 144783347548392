import React from 'react';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { IconButton } from '@material-ui/core';

import Image from '../image/Image';

const Back = () => {
  const history = useHistory();

  return (
    <Hidden mdUp>
      <IconButton onClick={() => history.goBack()}>
        <Image fileName="back-white.png" alt="Go back history arrow button" icon />
      </IconButton>
    </Hidden>
  );
};

export default Back;
