const addMaskTypesList = ['eid', 'cardNumber', 'expirationDate', 'dateYMD'];
const customMaskTypeList = ['eid', 'cardNumber', 'expirationDate'];

const maskConfig = {
  expirationDate: [0, 0, '/', 0, 0],
  cardNumber: [0, 0, 0, 0, ' ', 0, 0, 0, 0, ' ', 0, 0, 0, 0, ' ', 0, 0, 0, 0],
  eid: [0, 0, 0, 0, 0,' ', 0, 0, 0, 0, 0,' ', 0, 0, 0, 0, 0,' ', 0, 0, 0, 0, 0,' ', 0, 0, 0, 0, 0,' ', 0, 0, 0, 0, 0,' ', 0, 0],
  dateYMD: [0, 0, 0, 0, '/', 0, 0, '/', 0, 0]
};

// cardNumber => If you paste 16 numbers add 3 spaces in between with the maskConfig.cardNumber pattern
// eid => If you paste 32 numbers add 6 spaces in between with the maskConfig.eid pattern
const forceCustomMask = (value, type) => {
  let index = 0;
  const mainOutput = maskConfig[type].map((mask) => {
    if (typeof mask === 'string') {
      return mask;
    } 
      const output = value[index];
      index += 1;
      return output;
    ;
  }).join('');

  index = 0;
  return mainOutput;
};

// If the first number is not '0' or '1' add a '0'
const customExpirationDateMask = (value) => {
  if (value === '0' || value === '1') {
    return value;
  } 
    return `0${  value  }/`;
  ;
};

const maskValidator = (type, value) => {
  const output = [];
  const valueToArray = value.split('');

  valueToArray.forEach((glyph, i) => {
    if (typeof maskConfig[type][i] === 'string') output.push(glyph === maskConfig[type][i]);
  });

  return !output.includes(false);
};

const moveLastGlyph = (type, value, prevValue) => {
  const firstBlock = value.slice(0, -1);
  const separator = maskConfig[type].filter((v) => typeof v === 'string')[0] || ' ';
  const lastGlyph = value.slice(-1);
  const newValue = firstBlock + separator + lastGlyph;

  // Check 2nd time and if it is not valid return the prevValue
  const isMaskValid = maskValidator(type, newValue);
  if (isMaskValid) {
    return newValue;
  } 
    return prevValue;
  ;
};

const run = (type, value, prevValue) => {
  const nextMaskGlyph = maskConfig[type][value.length];
  const prevValueLastGlyph = prevValue.slice(-1);

  // Custom Rules
  if (customMaskTypeList.includes(type)) {
    // This list is just for fine tunning custom exceptions
    // Do not add an if with just (type === 'type') or you will block the main output
    if (type === 'eid' && value.length === 32 && !value.includes(' ')) {
      return forceCustomMask(value, 'eid');
    };
    if (type === 'cardNumber' && value.length === 16 && !value.includes(' ')) {
      return forceCustomMask(value, 'cardNumber');
    };
    if (type === 'expirationDate' && value.length === 1) {
      return customExpirationDateMask(value);
    };
    if (type === 'dateYMD') {
      return forceCustomMask(value, 'dateYMD');
    };
  };

  const isMaskValid = maskValidator(type, value);
  if (!isMaskValid) return moveLastGlyph(type, value, prevValue);

  // Main Output
  if (typeof nextMaskGlyph === 'string' && nextMaskGlyph !== prevValueLastGlyph) {
    return value + nextMaskGlyph;
  } 
    return value;
  ;
};

const mask = {
  run,
  addMaskTypesList
};

export default mask;
