import styled from 'styled-components';

import { theme } from '../../theme/theme';
// Deprecated. Use MUI instead

const Flexbox = styled.div`
  display: flex;
  flex: ${props => props.flex};
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  align-content: ${props => props.alignContent};

  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => `${theme.size.swell(props.verticalMarginSwell)  } 0px`};
  z-index: ${props => props.zIndex};
`;

Flexbox.defaultProps = {
  flex: '0 1 auto',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  height: '100%',
  verticalMarginSwell: 0, // 0px // margin: 0px 0px;
  zIndex: 0
};

export default Flexbox;

/*
Documentation

All is center by default
The props have the same mane of React Native flexbox camelcase style object.

https://reactnative.dev/docs/flexbox
https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox
http://flexbox.buildwithreact.com/
*/
