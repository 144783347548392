import colors from './colors';

/**
 * Keep this in sync with:
 * https://app.zeplin.io/project/5dde4f632bb48b1bbb26abbe/styleguide/textstyles?seid=5d0b5d0523d4a770c78443d4
 */
export default {
  h1: {
    fontFamily: 'HeronSansCond',
    fontSize: '96px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: colors.blue5
  },
  h2: {
    fontFamily: 'HeronSansCond',
    fontSize: '60px',
    fontWeight: 'bold',
    fontStretch: '1px',
    fontStyle: '1px',
    lineHeight: 1,
    letterSpacing: '1px',
    color: colors.blue5
  },
  h3: {
    fontFamily: 'HeronSansCond',
    fontSize: '32px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.05,
    letterSpacing: '1.5px',
    color: colors.blue5,
    '@media (min-width: 768px)': {
      fontSize: '44px'
    }
  },
  h4: {
    fontFamily: 'HeronSansCond',
    fontSize: '33px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.91,
    letterSpacing: '1.5px',
    color: colors.blue5
  },
  h5: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '-0.3px',
    color: colors.blue5,
    '@media (min-width: 768px)': {
      fontSize: '24px',
      letterSpacing: 'normal'
    }
  },
  h6: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: colors.blue5
  },
  subtitle1: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue5
  },
  subtitle2: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: '0.5px',
    color: colors.black
  },
  body1: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.25,
    letterSpacing: 'normal',
    color: '#0d313f'
  },
  body2: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.black
  },
  overline: {
    fontFamily: 'Inter',
    color: '#0d313f',
    fontSize: '14px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.5px'
  },
  caption: {
    fontFamily: 'Inter',
    color: colors.blue5,
    fontSize: '12px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.38',
    letterSpacing: '0.3px'
  },
  button: {
    fontSize: '16px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'center'
  },

  config: {
    h1: '40px',
    h2: '32px',
    h3: '24px',
    h4: '20px',
    h5: '16px',
    h6: '12px',
    p: '14px',

    h2SmallCaps: '24px'
  }
};
