import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typo from '../typo/CustomTypograph';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: (props) => (props.mb ? props.mb : '0'),
    width: '100%',
    backgroundColor: (props) => (props.bgcolor ? theme.colors[props.bgcolor] : 'transparent')
  }
}));

const SelectComponent = (props) => {
  const { OptionWrapper } = props;

  const elementsList =
    Array.isArray(props.data) &&
    props.data.length &&
    React.Children.toArray(
      props.data.map((obj) => {
        const item = (
          <MenuItem key={obj.code} value={obj.code}>
            {obj.label}
          </MenuItem>
        );

        if (OptionWrapper) {
          return (
            <MenuItem value={obj.code} key={obj.code}>
              <OptionWrapper code={obj.code}>{obj.label}</OptionWrapper>
            </MenuItem>
          );
        }

        return item;
      })
    );

  const handleChange = (event) => {
    props.onSelectFunction(event);
  };

  const classes = useStyles(props);

  return (
    <FormControl variant="outlined" className={classes.formControl} error={props.error}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        fullWidth
        inputProps={{
          name: props.name || 'select-name',
          id: props.name || 'select-id'
        }}
        value={props.selected}
        onChange={handleChange}
        label={props.label}
      >
        {elementsList}
      </Select>
      {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
      {props.error && (
        <Typo variant="caption" color="error">
          {props.errorMessage}
        </Typo>
      )}
    </FormControl>
  );
};

SelectComponent.defaultProps = {
  id: 'some-id',
  selected: ''
};

SelectComponent.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
    })
  ).isRequired,
  onSelectFunction: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  OptionWrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  bgcolor: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default SelectComponent;
