import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const CardSection = (props) => {
  const {children} = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {children}
    </Box>
  )
};

CardSection.propTypes = {
  children: PropTypes.node,
};

export default CardSection;

