import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';

import PageContainer from '../pageContainer/pageContainer';

export default function AppGenericErrorPage() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Alert severity="error">{t('somethingWentWrong')}</Alert>
    </PageContainer>
  );
}
