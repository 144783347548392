import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import Loading from './Loading';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgb(42 158 194 / 0.8)'
  }
}));

function LoadingBackdrop({open, onClick}) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
      <Loading thickness={6} size={80}/>
    </Backdrop>
  );
}

LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export default LoadingBackdrop;
