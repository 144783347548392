const SUPPORTED_CURRENCIES_BY_CARD = {
    visa: ['AUD', 'EUR', 'PLN', 'GBP', 'USD'],
    masterCard: ['AUD', 'EUR', 'PLN', 'GBP', 'USD'],
    americanExpress: [],
  }

const CARD_TYPE_LOGOS = [
  { id: 'masterCard', path: '/assets/images/cards/mastercard.svg' },
  { id: 'visa', path: '/assets/images/cards/visa.svg' },
  { id: 'americanExpress', path: '/assets/images/cards/amex.svg' }
]

const SUPPORTED_CARDS = ['masterCard', 'visa'];

module.exports = {
    SUPPORTED_CURRENCIES_BY_CARD,
    SUPPORTED_CARDS,
    CARD_TYPE_LOGOS
  };