import fonts from './fonts';
import colors from './colors';

export default () => ({
  MuiCssBaseline: {
    '@global': {
      '@font-face': [...fonts],
      a: {
        'text-decoration': 'none'
      },
      body: {
        backgroundColor: colors.cream
      }
    }
  },
  MuiContainer: {
    // maxWidthSm: {
    //   maxWidth: '645px !important'
    // }
  },
  MuiTypography: {
    body1: {
      lineHeight: '1.5'
    }
  },
  MuiPopover: {
    paper: {
      backgroundColor: '#fff'
    }
  },
  MuiButton: {
    root: {
      padding: '16px',
      fontFamily: 'Inter',
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    sizeSmall: {
      height: '38px',
      fontSize: '16px',
      paddingLeft: '0.8rem',
      paddingRight: '0.8rem'
    },
    sizeLarge: {
      height: '44px'
    },
    outlined: {
      color: colors.blue1,
      border: `solid 2px ${colors.blue1}!important`
    },
    contained: {
      backgroundColor: colors.blue5,
      color: colors.white,
      '&:hover, &:active': {
        backgroundColor: '#00171d',
        color: colors.white
      }
    }
  },
  MuiDialog: {
    paper: {
      backgroundColor: '#fff'
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: '34px'
    }
  },
  MuiIconButton: {
    root: {
      color: colors.blue5
    }
  },
  MuiInputLabel: {
    root: {
      '&$focused': {
        color: colors.blue5
      }
    },
    outlined: {
      transform: 'translate(14px, 14px) scale(1)',
      color: colors.colorTransparent2,

      '&$shrink': {
        color: colors.black
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      '&$focused $notchedOutline': {
        borderColor: `${colors.blue5}!important`
      }
    },
    input: {
      padding: '11.5px 14px',
      color: colors.black,
      backgroundColor: colors.white
    },
    adornedEnd: {
      paddingRight: 0
    }
    // BUG: https://github.com/mui-org/material-ui/issues/16442
    // MuiBackdrop: {
    //   root: {
    //     backgroundColor: 'rgb(42 158 194 / 0.8)'
    //   }
    // }
  }
});
