import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

export default function Spinner() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
    >
      <CircularProgress />
    </Box>
  );
};
