import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { useAuth } from '../../auth';

import Sidebar from './components/sidebar';
import { Topbar } from './components/topbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 64
  },
  paddingLeft: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 238
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto'
  },
  content: {
    flex: '1 1 auto',
    height: '100%'
  }
}));

function MainLayout({ children, hideMenu = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = useState(false);
  const { keycloak } = useAuth();
  const { profile } = keycloak;

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const isRenderSidebar = hideMenu ? false : Boolean(profile);
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div className={classes.root}>
      <Topbar hideMenu={hideMenu} onSidebarOpen={handleSidebarOpen} />
      {isRenderSidebar ? (
        <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
      ) : null}
      <div className={clsx({ [classes.paddingLeft]: isRenderSidebar }, classes.wrapper)}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
