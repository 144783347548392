import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useCustomerApi from '../../hooks/useCustomerApi';
import useFetchMyPlans from '../../hooks/useFetchMyPlans';
import { useAuth } from '../../auth';

import { types } from './actions';

const useAsyncActions = () => {
  const {
    request: getProfile,
    data: profile,
    loading: getProfileLoading,
    error: profileError
  } = useCustomerApi.getProfile();
  const { getMyPlans, dataProduct: plans, loading: plansLoading, error: plansError } = useFetchMyPlans();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    if (profile && !getProfileLoading && user) {
      const noRegisteredName = profile.first_name === profile.email || profile.last_name === profile.email;
      if (noRegisteredName) {
        profile.first_name = user.firstName;
        profile.last_name = user.lastName;
      }
      dispatch({ type: types.UPDATE_USER_PROFILE, payload: profile });
    }
  }, [getProfileLoading, profile, dispatch, user]);

  useEffect(() => {
    if (plans && !plansLoading) {
      dispatch({ type: types.UPDATE_PLANS, payload: plans });
    }
  }, [plansLoading, plans, plansError, dispatch]);

  return {
    getProfile,
    getProfileLoading,
    profileError,
    getMyPlans,
    plansLoading,
    plansError
  };
};

export default useAsyncActions;
