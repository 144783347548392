/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Modal } from '@material-ui/core';

import Image from '../image/Image';

const useStyles = makeStyles((theme) => ({
  box: {
    borderBottom: '1px solid rgba(13, 49, 63, .1)',
    padding: '1.125rem 1.5625em',
    margin: 0
  },
  popupBlock: {
    zIndex: '10',
    position: 'fixed',
    maxWidth: '392px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    right: '1rem',
    bottom: '2rem',
    display: 'none',
    boxShadow: '0 20px 60px 0 rgba(0, 0, 0, 0.08), 0 20px 40px 0 rgba(0, 0, 0, 0.05)',

    '&.isOpen': {
      display: 'block'
    },

    [theme.breakpoints.down('xs')]: {
      width: '300px'
    }
  },
  bottom: {
    display: 'flex',
    padding: '1.125rem 1.5625em'
  },
  buttonBottom: {
    minWidth: '9rem',
    marginLeft: 'auto'
  },
  imgButton: {
    cursor: 'pointer'
  },
  img: {
    [theme.breakpoints.down('xs')]: {
      width: '250px'
    }
  },
  buttonFixed: {
    zIndex: '2',
    position: 'fixed',
    bottom: (props) => (props.avoidFooter ? theme.spacing(11) : '.5rem'),
    right: '.5rem',
    fontSize: '.85rem',
    letterSpacing: '0.07px',
    borderRadius: '16px',
    backgroundColor: theme.colors.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.blue1,
    color: theme.colors.blue1,
    padding: '.35rem .7rem',
    fontWeight: 'normal',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.white,
      color: theme.colors.blue1,
      boxShadow: '0 4px 5px 0 rgba(13, 49, 63, 0.05), 0 1px 5px 0 rgba(13, 49, 63, 0.05)'
    }
  },
  iconSmall: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '20px',
    height: '20px',
    color: 'inherit',
    fill: 'currentColor',
    flexShrink: 0,
    marginRight: '5px'
  },
  modalDiv: {
    position: 'absolute',
    maxWidth: 600,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.colors.black,
    boxShadow: theme.shadows[5],
    padding: 0,
    '&:focus': {
      outline: 'none'
    }
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    height: 0
  },
  videoWrapperIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  closeButton: {
    position: 'absolute',
    top: '6px',
    right: '6px',
    width: 20,
    height: 20,
    color: theme.colors.white,
    cursor: 'pointer'
  }
}));

const Popup = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [openModalIframe, toggleModalIframe] = useState(false);

  return (
    <>
      <Button className={classes.buttonFixed} onClick={() => setIsPopupOpen(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classes.iconSmall}>
          <path d="M13.68 13.74l-.189.07c-.361.2-.59.59-.59 1v.11h-2v-.11c0-1.2.699-2.31 1.779-2.83l.19-.07c.861-.44 1.3-1.41 1.081-2.39a2.083 2.083 0 0 0-1.541-1.54c-.66-.15-1.31-.01-1.83.4-.4.32-.67.78-.759 1.28L8.05 8.6a3.99 3.99 0 0 1 1.29-1.78 4.1 4.1 0 0 1 3.52-.79 4.072 4.072 0 0 1 3.05 3.05c.43 1.91-.49 3.83-2.23 4.66M12 19.39c-.81 0-1.469-.66-1.469-1.47 0-.81.659-1.47 1.469-1.47.811 0 1.47.66 1.47 1.47 0 .81-.659 1.47-1.47 1.47M11.96 2C6.46 2 2 6.46 2 11.96c0 5.5 4.46 9.96 9.96 9.96 5.5 0 9.96-4.46 9.96-9.96 0-5.5-4.46-9.96-9.96-9.96" />
        </svg>
        {t('popupLearnMore.buttonLabel')}
      </Button>

      <div id="js-popup" className={classes.popupBlock + (isPopupOpen ? ' isOpen' : '')}>
        <h3 className={classes.box}>{t('popupLearnMore.title')}</h3>
        <div className={classes.box}>
          <div className={classes.imgButton} onClick={() => toggleModalIframe(true)}>
            <Image className={classes.img} fileName="eSIM_video.jpg" alt="esim-video" />
          </div>
          <div>
            <p>
              {t('popupLearnMore.content')}
              <a href={t('popupLearnMore.linkHowToAddPlan')} size="small" variant="caption" target="_blank" rel="noopener noreferrer">
                {t('here')}.
              </a>
            </p>
          </div>
        </div>
        <div className={classes.bottom}>
          <Button size="small" color="primary" variant="contained" onClick={() => setIsPopupOpen(false)} className={classes.buttonBottom}>
            {t('popupLearnMore.closeButton')}
          </Button>
        </div>
      </div>

      <Modal
        open={openModalIframe}
        onClose={() => toggleModalIframe(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.modalDiv}>
          <div className={classes.videoWrapper}>
            <iframe
              title={t('ewatch.title')}
              className={classes.videoWrapperIframe}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/C97Jr2sVl0Y?t=41&autoplay=1"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <svg
            className={classes.closeButton}
            onClick={() => toggleModalIframe(false)}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M11.3 10.357c.26.261.26.682 0 .943-.261.26-.683.26-.943 0L8 8.943 5.643 11.3c-.26.26-.683.26-.943 0-.26-.26-.26-.682 0-.943L7.057 8 4.7 5.643c-.26-.26-.26-.682 0-.942.26-.261.683-.261.943 0L8 7.057l2.357-2.356c.26-.261.682-.261.942 0 .261.26.261.682 0 .942L8.943 8l2.356 2.357zM8 .667C3.95.667.667 3.95.667 8c0 4.05 3.283 7.333 7.333 7.333 4.05 0 7.333-3.282 7.333-7.333C15.333 3.95 12.05.667 8 .667z"
            />
          </svg>
        </Box>
      </Modal>
    </>
  );
};

Popup.defaultProps = {
  avoidFooter: false
};

Popup.propTypes = {
  avoidFooter: PropTypes.bool
};

export default Popup;
