import React from 'react';
import { Link } from 'react-router-dom';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Box, Button, Divider, Grid, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HelpIcon from '@material-ui/icons/Help';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';
import { useSelector } from 'react-redux';

import CustomTypograph from '../../../../commons/typo/CustomTypograph';
import { MenuPopver } from '../../../../commons';
import ProfilePopper from '../../../../pages/profile/ProfilePopper';
import routes from '../../../../../constants/routes';
import { getUserProfile } from '../../../../../redux/selectors';
import { useAuth } from '../../../../../auth';
import useCustomerNaming from '../../../../../hooks/useCustomerNaming';

import HelpMenu from './HelpMenu';

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50vw'
    }
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logOutHeader: {
    backgroundColor: theme.colors.white,
    color: theme.colors.blue5
  },
  buttonLogin: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '45vw',
      marginRight: '5vw'
    }
  },
  imgWidth: {
    width: '100%'
  },
  menuButton: {
    marginLeft: -theme.spacing(2)
  }
}));

const Topbar = (props) => {
  const { keycloak } = useAuth();
  const { className, onSidebarOpen, hideMenu, ...rest } = props;
  const classes = useStyles();
  const profile = useSelector(getUserProfile);
  const { t } = useTranslation('');
  const { name: userName } = useCustomerNaming();

  const renderProfilePopperWeb = () => (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Divider orientation="vertical" flexItem />

      <IconButton style={{ color: 'white' }}>
        <FaceIcon />
      </IconButton>

      {/* Change this to get user's name and email */}
      <Box flexDirection="column" ml={1}>
        <CustomTypograph variant="body2Light">{userName}</CustomTypograph>
      </Box>

      <IconButton color="inherit">
        <ExpandMoreIcon style={{ color: 'white' }} />
      </IconButton>
    </Grid>
  );

  const renderProfilePopperMobile = () => (
    <IconButton style={{ color: 'white' }}>
      <FaceIcon />
    </IconButton>
  );

  return (
    <AppBar {...rest} className={keycloak.authenticated ? '' : classes.logOutHeader}>
      <Toolbar>
        {!hideMenu && keycloak.authenticated && (
          <Hidden lgUp>
            <IconButton className={classes.menuButton} color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}

        <Box display="flex" className={classes.logo} component={Link} to={routes.DASHBOARD}>
          {keycloak?.authenticated ? (
            <img className={classes.imgWidth} alt="Logo" src="/assets/images/BetterRoaming_Logo_White.svg" />
          ) : (
            <img className={classes.imgWidth} alt="Logo" src="/assets/images/BetterRoaming_Logo.svg" />
          )}
        </Box>

        <div className={classes.flexGrow} />

        {/* <Hidden lgUp>
          <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <SearchIcon />
            </Badge>
          </IconButton>
        </Hidden> */}

        {keycloak?.authenticated ? (
          <>
            <MenuPopver ContentComponent={HelpMenu}>
              <HelpIcon />
            </MenuPopver>
            <Hidden mdDown>
              <ProfilePopper profile={profile} popperButton={renderProfilePopperWeb()} />
            </Hidden>

            <Hidden lgUp>
              <ProfilePopper profile={profile} popperButton={renderProfilePopperMobile()} />
            </Hidden>
          </>
        ) : (
          <>
            <Button component={Link} to={routes.DASHBOARD} className={classes.buttonLogin} size="small" variant="contained" color="primary">
              {t('profile.login')}
            </Button>
            <MenuPopver ContentComponent={HelpMenu}>
              <HelpIcon />
            </MenuPopver>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  hideMenu: PropTypes.bool
};

export default Topbar;
