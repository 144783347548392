import React from 'react';
import { string, node, bool } from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

import Typo from '../typo/CustomTypograph';
import { Image, Back } from '..';
// import CancelPlan from '../../pages/plans/planDetails/CancelPlan';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    right: 0,
    top: 0,
    height: 160,
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-end',

    [theme.breakpoints.down('md')]: {
      width: '100%'
    },

    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      top: (props) => props.top
    }
  },
  container: {
    padding: theme.spacing(3)
  },
  detailContainer: {
    position: 'absolute',
    top: 0,
    height: 160,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(7),
    left: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start'
    }
  },
  absoluteImage: {
    position: 'absolute',
    objectFit: 'cover',
    top: 0,
    height: 160,
    width: '100%',
    left: 0,
    right: 0
  },
  actionContainer: {
    transform: 'translate(0%, -100%)'
  },
  planText: {
    marginTop: '1rem'
  }
}));

const Header = (props) => {
  const classes = useStyles(props);
  const { title, subtitle } = props;

  const renderActions = () => {
    if (!props.actions) return null;
    return (
      <Box display="flex">
        {/* TODO: this should not go into production until it's complete, that's why it's commented for now
        <CancelPlan /> */}
        {props.actions}
      </Box>
    );
  };

  return props.isDetail ? (
    <Box className={classes.root}>
      <Image fileName={props.backgroundImage} alt="header background" className={classes.absoluteImage} />
      <Grid container className={classes.detailContainer} alignItems="flex-end">
        <Grid item>
          <Grid container justify="flex-end" alignItems="center">
            <Back />
            <Typo variant="h6Light">
              <b>{title}</b>
            </Typo>
          </Grid>
          <Typo variant="body1Light">
            <b>{subtitle}</b>
          </Typo>
        </Grid>

        <Grid item container className={classes.actionContainer} justify="flex-end">
          {renderActions()}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Hidden xsDown>
      <Grid container className={classes.container}>
        <Grid sm={6} md={6} lg={6} item>
          <Typo variant="h5">{title}</Typo>
          {subtitle && (
            <Typo className={classes.planText} variant="h6">
              {subtitle}
            </Typo>
          )}
        </Grid>

        <Grid sm={6} md={6} lg={6} item container className={classes.actionContainer} justify="flex-end">
          {renderActions()}
        </Grid>
      </Grid>
    </Hidden>
  );
};

Header.propTypes = {
  title: string,
  subtitle: string,
  actions: node,
  isDetail: bool,
  backgroundImage: string
};

export default Header;
