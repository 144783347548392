export const types = {
  UPDATE_FILLED_PROFILE_FLAG: 'user/UPDATE_FILLED_PROFILE_FLAG',
  UPDATE_USER_PROFILE: 'user/UPDATE_USER_PROFILE',
  UPDATE_PLANS: 'user/UPDATE_PLANS'
};

export const updateFilledProfileFlag = (hasCompletedCustomer) => ({
  type: types.UPDATE_FILLED_PROFILE_FLAG,
  payload: hasCompletedCustomer
});
