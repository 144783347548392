import React from 'react';
import styled from 'styled-components';

import { theme } from '../../theme/theme';

const BlobCombined = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="340" height="330" viewBox="0 0 340 330">
      <g fill="#299DC1" fillRule="evenodd">
        <path
          fillOpacity=".5"
          d="M168.238.505C132.882.074 97.008 15.359 63.079 47.258L52.272 57.422c-67.085 63.072-69.67 137.9-3.502 204.611l16.782 16.923c63.256 63.778 140.297 69.42 210.15 3.74l10.812-10.164c67.725-63.674 69.82-137.746 3.503-204.611L273.23 51C241.013 18.51 205.591 1.372 169.591.528l-1.353-.024z"
        />
        <path
          fillOpacity=".7"
          d="M116.01 21.388C84.935 32.278 58.414 57.09 38.885 95.83l-6.219 12.341c-38.612 76.597-17.044 142.931 62.098 180.251l20.073 9.47c75.66 35.68 144.866 16.069 185.068-63.692l6.225-12.343c38.98-77.327 17.224-142.845-62.098-180.252l-20.075-9.465c-38.538-18.178-74.99-21.894-106.757-11.162l-1.192.41z"
        />
      </g>
    </svg>
  );

const BlobSimple = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="170" height="177" viewBox="0 0 170 177">
      <path
        fill="#299DC1"
        fillRule="evenodd"
        d="M165.758 58.5c-6.134-17.585-20.112-32.594-41.935-43.645l-6.952-3.52C73.72-10.515 36.353 1.69 15.329 46.477l-5.334 11.36c-20.1 42.817-9.053 81.982 35.88 104.733l6.953 3.523c43.56 22.06 80.469 9.747 101.541-35.142l5.332-11.361c10.24-21.81 12.334-42.438 6.288-60.416l-.23-.674z"
      />
    </svg>
  );

const BackgroundImage = styled.div`
  svg {
    position: absolute;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    top: ${(props) => (props.top ? props.top : 'auto')};
    bottom: ${(props) => (props.bottom ? props.bottom : 'auto')};
    left: ${(props) => (props.left ? props.left : 'auto')};
    right: ${(props) => (props.right ? props.right : 'auto')};
  }
`;

const Frame = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  z-index: 0;
  background-color: ${theme.colors.cream};
  @media (max-width: ${theme.size.mediaQueries.mobileXL}) {
    display: none;
  }
`;

const BackgroundImages = () => (
    <Frame>
      <BackgroundImage width="385px" height="372px" right="-50px" bottom="-50px">
        <BlobSimple />
      </BackgroundImage>
      <BackgroundImage width="198px" height="191px" right="370px" bottom="50px">
        <BlobSimple />
      </BackgroundImage>
      <BackgroundImage width="80px" height="77px" right="150px" bottom="350px">
        <BlobSimple />
      </BackgroundImage>
      <BackgroundImage width="35px" height="34px" right="50px" bottom="320px">
        <BlobCombined />
      </BackgroundImage>
    </Frame>
  );

export default BackgroundImages;
