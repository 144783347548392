import { types } from './actions';

const INITIAL_CREDIT_CARD_STATE = { number: '', cvv: '', expireDate: '', name: '', valid: false };

const INITIAL_STATE = {
  cart: [],
  payment: {
    creditCard: INITIAL_CREDIT_CARD_STATE
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_PRODUCT:
      return {
        ...state,
        cart: [action.payload]
      };
    case types.ADD_CREDIT_CARD:
      return {
        ...state,
        payment: {
          creditCard: action.payload
        }
      };
    case types.CLEAR_CREDIT_CARD:
      return {
        ...state,
        payment: {
          creditCard: INITIAL_CREDIT_CARD_STATE
        }
      };
    case types.CLEAR_CART:
      return {
        ...state,
        cart: []
      }
    default:
      return state;
  }
};
