import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Typo from '../typo/CustomTypograph';

import ImageCard from './ImageCard';


const useStyles = makeStyles((theme) => ({
  titleBar: {
    width: '100%',
    height: theme.spacing(10),
  },
  infoBar: {
    width: '100%',
    height: theme.spacing(12.5),
    background: theme.palette.primary.contrastText,
    borderTopLeftRadius: theme.size.borderRadiusCards,
    borderTopRightRadius: theme.size.borderRadiusCards,
  },
  price: {
    marginInlineEnd: theme.spacing(4),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBlock: theme.spacing(1),
    background: theme.colors.green,
    borderRadius: theme.size.borderRadius,
    textAlign: 'center',
    color:theme.colors.black
  },
  white: {
    color: theme.colors.white
  },
  black: {
    color: theme.colors.black
  },
  planTitle: {
    lineHeight: 1.4,
    color:theme.colors.black,
    fontSize:'24px',
    fontWeight:'bold',
    [theme.breakpoints.down('xs')]:{
      fontSize:'16px'
    }
  },
  planInfo:{
    lineHeight: 1.4,
    color:theme.colors.black,
    fontSize:'16px',
    [theme.breakpoints.down('sm')]:{
      fontSize:'14px',
    }
  }
}));

const CountryCard = (props) => {
  const classes = useStyles();

  return (
    <ImageCard
      imageFileName={props.imageFileName}
      imageAlt={props.imageAlt}
      content={
        <>
          <Box className={classes.titleBar}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <Typo variant="h5" className={classes.white}><b>{props.title}</b></Typo>
              <Typo variant="body2" className={classes.white}>
                {props.subtitle}
              </Typo>
            </Box>
          </Box>
          <Box className={classes.infoBar}>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              height='100%'
            >
              <Box ml={2}>
                <Typo variant="h6" className={classes.planTitle}>{props.planTitle}</Typo>
                <Typo variant="body2" className={classes.planInfo}>{props.planInfo}</Typo>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                alignContent='center'
                className={classes.price}
              >
                <Typo variant="body2" color="inherit">
                  {props.price}
                </Typo>
              </Box>
            </Box>
          </Box>
        </>
      }
    />
  );
};

CountryCard.defaultProps = {
  imageFileName: 'planEurope.png',
  imageAlt: 'country',
  title: 'Europe',
  subtitle: 'Device Plan',
  planTitle: 'Unlimited',
  planInfo: 'GB / voice / SMS',
  price: '7.99$/month'
};

export default CountryCard;
