import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { capitalize } from '@material-ui/core';

const body3 = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal'
};

const propsForLight = (theme) => ({
  display: 'block',
  color: theme.palette.text.light,
  margin: `${theme.spacing(1)}px auto 0`
});

const styles = (theme) => ({
  h1Light: {
    ...theme.typography.h1,
    ...propsForLight(theme)
  },
  h2Light: {
    ...theme.typography.h2,
    ...propsForLight(theme)
  },
  h3Light: {
    ...theme.typography.h3,
    ...propsForLight(theme),
    letterSpacing: '2px'
  },
  h3Dark: {
    ...theme.typography.h3,
    letterSpacing: '2px',
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`
  },
  h4Light: {
    ...theme.typography.h4,
    ...propsForLight(theme)
  },
  h5Light: {
    ...theme.typography.h5,
    ...propsForLight(theme)
  },
  h6Light: {
    ...theme.typography.h6,
    ...propsForLight(theme)
  },
  subtitle1Light: {
    ...theme.typography.subtitle1,
    ...propsForLight(theme)
  },
  subtitle2Light: {
    ...theme.typography.subtitle2,
    ...propsForLight(theme)
  },
  body1Light: {
    ...theme.typography.body1,
    ...propsForLight(theme)
  },
  body2Light: {
    ...theme.typography.body2,
    color: theme.palette.text.light,
    margin: '0 auto'
  },
  body2LightBold: {
    ...theme.typography.body2,
    color: theme.palette.text.light,
    fontWeight: 'bold',
    margin: '0 auto'
  },
  overlineLight: {
    ...theme.typography.overline,
    ...propsForLight(theme)
  },
  captionLight: {
    ...theme.typography.caption,
    ...propsForLight(theme)
  },
  body3: {
    ...body3,
    color: theme.palette.text.primary,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`
  },
  body3Light: {
    ...body3,
    ...propsForLight(theme)
  }
});

const Typography = (props) => {
  const { children, classes, marked, variant, ...other } = props;
  const customTypoProps = classes[variant] ? { className: classes[variant] } : { variant };

  return (
    <MuiTypography {...other} {...customTypoProps}>
      {children}
      {marked ? <span className={classes[`marked${capitalize(variant) + capitalize(marked)}`]} /> : null}
    </MuiTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  marked: PropTypes.bool
};

Typography.defaultProps = {
  marked: false
};

export default withStyles(styles)(Typography);
