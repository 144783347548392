import { getCookieValue } from './utils/cookie';

const CANARY_COOKIE_NAME = 'betterroaming-version';

const getCanaryCookieValue = () => (getCookieValue(CANARY_COOKIE_NAME)?.includes('latest') ? 'latest' : 'stable');

const setupAxiosInterceptors = (axiosInstance, keycloak) => {
  const onRequest = (config) => {
    const newConfig = { ...config };

    if (keycloak) {
      const bearer = keycloak.token;
      if (bearer) {
        newConfig.headers.common.Authorization = `Bearer ${bearer}`;
      }
    }

    newConfig.headers[CANARY_COOKIE_NAME] = getCanaryCookieValue();
    return newConfig;
  };

  axiosInstance.interceptors.request.use(onRequest, Promise.reject);
};

export default setupAxiosInterceptors;
