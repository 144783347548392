import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { Image } from "..";
import Typo from '../typo/CustomTypograph';

const DEFAULT_IMAGE_HEIGHT = 100;

const useStyles = makeStyles((theme) => ({
  layerBox: {
    position: 'relative',
    zIndex: 1
  },
  mask: {
    position: 'absolute',
    width: "100%",
    height: (props) => props.imageHeight,
    zIndex: 2,
    background: 'linear-gradient(to bottom, rgba(13, 49, 63, 0.1), rgba(13, 49, 63, 0.85) 76%);',
  },
  imageContent: {
    width: "100%",
    height: (props) => props.imageHeight,
    position: 'absolute',
    zIndex: 3
  },
  img: {
    width: '100%',
    height: (props) => props.imageHeight,
    objectFit: 'cover'
  },
  white: {
    color: theme.palette.primary.contrastText
  }
}));


const CountryImageBlock = (props) => {
  const classes = useStyles(props);
  const {
    alt,
    imageTitle,
    imageSubtitle,
    backgroundImage
  } = props;

  return (
    <Box className={classes.layerBox}>
      <Box className={classes.mask} />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
        className={classes.imageContent}
      >
        <Typo variant="h5" className={classes.white}>
          <b>{imageTitle}</b>
        </Typo>
        <Typo variant="body2" className={classes.white}>
          {imageSubtitle}
        </Typo>
      </Box>
      <Image
        fileName={backgroundImage}
        alt={alt || ''}
        className={classes.img}
      />
    </Box>
  );
};

CountryImageBlock.defaultProps = {
  imageHeight: DEFAULT_IMAGE_HEIGHT,
  alt: 'No title',
};

export default CountryImageBlock;
