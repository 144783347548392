import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { AppLoading, ErrorMessageDialog } from '../ui/commons';
import routes from '../constants/routes';
import { useAuth } from '../auth/keycloakContext';
import { useUserActions } from '../redux/user';

import { PrivateRoute, PublicRoute } from './utils';

const CheckoutResolver = React.lazy(() => import('./routeResolvers/checkoutResolver'));
const MyPlansPage = React.lazy(() => import('../ui/pages/plans/myPlans/MyPlansPage'));
const PlanDetailsPage = React.lazy(() => import('../ui/pages/plans/planDetails/planDetailsPage'));
const PlanSelectedPage = React.lazy(() => import('../ui/pages/plans/PlanSelectedPage'));
const SelectPlanPage = React.lazy(() => import('../ui/pages/plans/SelectPlanPage'));
const OrderPage = React.lazy(() => import('../ui/pages/order/OrderPage'));
const LoginPage = React.lazy(() => import('../ui/pages/login'));
const LoginRegisterPage = React.lazy(() => import('../ui/pages/login/Register'));
const ProfilePage = React.lazy(() => import('../ui/pages/profile'));
const ErrorPage = React.lazy(() => import('../ui/pages/error/ErrorPage'));
const SetupComplete = React.lazy(() => import('../ui/pages/setup-complete/SetupComplete'));

function RedirectResolver() {
  return <Redirect to={routes.SELECTPLAN} />;
}

export default function Routes() {
  const { keycloak } = useAuth();
  const { getProfile } = useUserActions();

  useEffect(() => {
    if (keycloak.authenticated) getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<AppLoading />}>
      <>
        <Switch>
          <Route path={routes.LOGIN} component={LoginPage} />
          <Route path={routes.REGISTER} component={LoginRegisterPage} />
          <PrivateRoute path={`${routes.PROFILE}/:tab?`} component={ProfilePage} />
          <PublicRoute path={`${routes.PLANS}/:productCountryCode`} component={PlanSelectedPage} hideMenu />
          <PublicRoute path={routes.SELECTPLAN} component={SelectPlanPage} hideMenu />
          <PublicRoute path={routes.PLANS}>
            <Redirect to={`${routes.SELECTPLAN}`} />
          </PublicRoute>
          <PublicRoute path={routes.SETUP} component={SetupComplete} />
          <PrivateRoute isDetail path="/plan/:planId?" component={PlanDetailsPage} />
          <PrivateRoute path={routes.DASHBOARD} component={MyPlansPage} />
          <PrivateRoute hideMenu path="/checkout/:productId/:stateIndex?" component={CheckoutResolver} />
          <PrivateRoute hideMenu path="/order/:orderId&:productCountryCode" component={OrderPage} />
          <Route path={routes.NOT_FOUND} component={() => <ErrorPage status={404} />} />
          <Route path={routes.FORBIDDEN} component={() => <ErrorPage status={403} />} />
          <Route path={routes.UNAUTHORIZED} component={() => <ErrorPage status={401} />} />
          <Route path={routes.BAD_REQUEST} component={() => <ErrorPage status={400} />} />
          <Route path={routes.SERVER_ERROR} component={() => <ErrorPage status={500} />} />
          <Route path={routes.BACKEND_UNAVAILABLE} component={() => <ErrorPage status={503} />} />
          <Route path="/health" component={() => React.Fragment} />
          <RedirectResolver />
        </Switch>
        <ErrorMessageDialog />
      </>
    </Suspense>
  );
}
