import React, { useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';
import Bugsnag from '@bugsnag/js';

import api from '../config/api';
import { AppLoading } from '../ui/commons';

import { KeycloakContext } from './keycloakContext';

const KeycloakProvider = ({ children, config }) => {
  const [keycloak, setKeycloak] = useState(undefined);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const initAuth = async () => {
      const keycloak = Keycloak(config);
      api.setKeycloak(keycloak);
      try {
        await keycloak.init({
          onLoad: 'check-sso',
          checkLoginIframe: false
        });
        setKeycloak(keycloak);
        if (keycloak.authenticated) {
          setUser(await keycloak.loadUserProfile());
        }
      } catch (e) {
        console.error('Something went wrong during authentication config');
      }
    };
    initAuth();
  }, [config]);

  useEffect(() => {
    if (user) {
      const id = user.attributes && user.attributes.u && user.attributes.u[0];
      Bugsnag.setUser(id, user.email, user.username);
    }
  }, [user]);

  if (!keycloak) return <AppLoading />;

  return (
    <KeycloakContext.Provider
      value={{
        keycloak,
        user,
        refreshUser: async () => {
          setUser(await keycloak.loadUserProfile());
        }
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

export default KeycloakProvider;
