import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Box, Menu, MenuItem, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { func, shape, oneOfType, node, element, string, arrayOf } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme.palette.text.light,

    '&:hover': {
      color: theme.palette.text.light,
    },

    // [theme.breakpoints.down('xs')]: {
    //   color: theme.palette.text.light,
    //   backgroundColor: 'transparent',
    // }
  }
}));

const ITEM_HEIGHT = 48;
const MENU_MAX_HEIGHT = ITEM_HEIGHT * 4.5;

const CustomMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { menuItems } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItems = () => {
    if (Array.isArray(menuItems) && menuItems.length > 0) {
      return menuItems.map((option) => (
        option.content ||
        <MenuItem
          key={option.id}
          onClick={() => {
            option.handleClick();
            handleClose();
          }}>
          {option.title}
        </MenuItem>
      ));
    }

    return null
  };

  return (
    <Box display="flex" flex={1} justifyContent="flex-end">

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.icon}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: MENU_MAX_HEIGHT,
              // This line is blocking the width from the childs
              // width: '20ch',
              backgroundColor: 'white',
              marginTop: 50
            }
          }}
        >
          {handleMenuItems()}
        </Menu>

    </Box>
  );
};

CustomMenu.propTypes = {
  actions: func,
  menuItems: arrayOf(
    shape({
      id: string.isRequired,
      title: string,
      handleClick: func.isRequired,
      content: oneOfType([node, element])
    })
  )
};

export default CustomMenu;
