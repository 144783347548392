import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  color: red;
`;

function SuspenseCustom({ fallback, loading, children, error, title }) {
  const { t } = useTranslation();
  if (error) {
    return <ErrorContainer>{`${t('somethingWentWrong')} ${title}.`}</ErrorContainer>;
  }

  return loading ? fallback : children;
}

SuspenseCustom.defaultProps = {
  title: 'this component'
};

SuspenseCustom.propTypes = {
  fallback: PropTypes.element.isRequired,
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  error: PropTypes.bool
};

export default SuspenseCustom;
