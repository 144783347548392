import { session, local } from "../config/utils"

export const loadFromSession = (key) => {
  try {
   return session.get(key);
  } catch(e) {
    return undefined;
  }
};

export const saveInSession = (key, data) => {
  try {
    session.set(key, data);
  } catch(e) {
    console.warn(e.message, '\n ERROR TO SAVE IN SESSION STORAGE');
  }
};

export const loadFromLocal = (key) => {
  try {
   return local.get(key);
  } catch(e) {
    return undefined;
  }
};

export const saveInLocal = (key, data) => {
  try {
    local.set(key, data);
  } catch(e) {
    console.warn(e.message, '\n ERROR TO SAVE IN SESSION STORAGE');
  }
};
