const multiple = 8;

export const swell = (x) => `${multiple * x  }px`;

const size = {
  multiple,
  swell,
  borderRadius: '4px',
  borderRadiusCards: '8px',
  borderWidth: '1px',
  minWidthMobile: swell(34), // 272px
  minWidth: swell(40), // 320px
  maxWidth: swell(62), // 496px
  minHeight: swell(7), // 56px
  mediaQueries: {
    mobile: '480px',
    mobileXL: '768px',
    table: '980px',
    desktop: '1200px'
  }
};

export default size;

/* Documentation
import { theme } from '../../theme/theme.js';

theme.size.swell(1) => 8px
theme.size.swell(2) => 16px
theme.size.swell(3) => 24px
theme.size.swell(4) => 32px
...
theme.size.swell(7) => 56px
theme.size.swell(8) => 64px
...
theme.size.swell(40) => 320px
...
*/
