/* eslint-disable no-return-await */
import axios from 'axios';
import Bugsnag from '@bugsnag/js';

import setupAxiosInterceptors from './axios-interceptor';
import { env } from './utils/getEnv';

export const axiosInstance = axios.create({
  baseURL: env.REACT_APP_API_URL
});

export const publicAxiosInstance = axios.create({
  baseURL: env.REACT_APP_PUBLIC_URL
});

class ApiService {
  setKeycloak(keycloak) {
    this.keycloak = keycloak;
    setupAxiosInterceptors(axiosInstance, this.keycloak);
    setupAxiosInterceptors(publicAxiosInstance);
  }

  getToken() {
    return this.keycloak.token;
  }

  async updateToken() {
    try {
      Bugsnag.leaveBreadcrumb('updateToken required');
      await this.keycloak.updateToken();
    } catch (e) {
      Bugsnag.leaveBreadcrumb('updateToken failed', { error: JSON.stringify(e) });
    }
  }

  async request({ method, url, data, publicRequest = false }) {
    const isUserAuthenticated = this.keycloak && this.keycloak.authenticated;
    if (isUserAuthenticated) {
      if (this.keycloak.isTokenExpired(120)) {
        await this.updateToken();
      }
    } else {
      await this.updateToken();
    }
    return publicRequest ? publicAxiosInstance({ method, url, data }) : axiosInstance({ method, url, data });
  }

  // GET, POST, PUT, DELETE already implies a 'request'
  // this is already an 'api' object, no need to repeat concepts
  // also, consistency is good ...

  async GET(url) {
    return await this.requestGET(url);
  }

  async requestGET(url) {
    return await this.request({ method: 'GET', url });
  }

  async publicRequestGET(url) {
    return await this.request({ method: 'GET', url, publicRequest: true });
  }

  async POST(url, data) {
    return await this.requestPost(url, data);
  }

  async requestPost(url, data) {
    return await this.request({ method: 'POST', url, data });
  }

  async PUT(url, data) {
    return await this.requestPUT(url, data);
  }

  async requestPUT(url, data) {
    return await this.request({ method: 'PUT', url, data });
  }

  async PATCH(url, data) {
    return await this.request({ method: 'PATCH', url, data });
  }

  async DELETE(url, data) {
    return await this.request({ method: 'DELETE', url, data });
  }
}

export default new ApiService();
