// Deprecated. Use MUI Typography instead
import styled from 'styled-components';
// Deprecated. Use MUI Typography instead

const getFontFamily = ({ fontFamily }) => {
  const fontFamilyValues = {
    regular: 'Inter-Regular',
    medium: 'Inter-Medium',
    bold: 'Inter-Bold',
    black: 'Inter-Black'
  };

  return {
    fontFamily: fontFamilyValues[fontFamily]
  };
};

const BaseText = styled.span`
  ${getFontFamily};
`;

BaseText.defaultProps = {
  color: 'dark1',
  fontFamily: 'regular',
  align: 'left',
  alignSelf: 'center',
  textDecoration: 'none',
  mBottom: 0,
  mLeft: 0,
  mTop: 0,
  mRight: 0
};
// Deprecated. Use MUI Typography instead
export default BaseText;
