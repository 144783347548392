import React from 'react';
import PropTypes from 'prop-types';

const PATH = '/assets/images/';
const PATH_ICON = '/assets/icons/';

const trimExtension = (file) => {
  if (!file.includes('.')) {
    console.warn(
      `The prop fileName should have this format => applePay.png\n
      The files names should have this format =>  applePay.png, applePay@2x.png, applePay@3x.png\n
      The images files should be on this path => public/${PATH}\n
      The icon files should be on this path => public/${PATH_ICON}`
    );
    return {
      name: null,
      extension: null
    };
  }

  const contentArray = file.split('.');
  return {
    name: contentArray[0],
    extension: `.${contentArray[1]}`
  };
};

const Image = (props) => {
  const dataObj = trimExtension(props.fileName);
  if (dataObj.name === null || dataObj.extension === null) return null;

  const currentPath = props.icon ? PATH_ICON : PATH;

  const srcSet =
    dataObj.extension !== '.svg'
      ? [
          `${currentPath + props.fileName} 1x`,
          `${currentPath + dataObj.name}@2x${dataObj.extension} 2x`,
          `${currentPath + dataObj.name}@3x${dataObj.extension} 3x`
        ].join(', ')
      : undefined;

  return <img className={props.className} src={currentPath + props.fileName} srcSet={srcSet} alt={props.alt} />;
};

Image.defaultProps = {
  fileName: 'applePay.png',
  alt: 'image',
  icon: false
};

Image.propTypes = {
  fileName: PropTypes.string,
  alt: PropTypes.string,
  icon: PropTypes.bool
};

export default Image;
