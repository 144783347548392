export const CONFIRMED = 'Confirmed';
export const ACTIVE = 'Active';

export default class PlanUtils {
  static isStatusActive(status) {
    return status === ACTIVE;
  }

  static isPlanActive(plan) {
    return plan && this.isStatusActive(plan.status);
  }

  static isEsimStatusUninstalled(status) {
    return status === CONFIRMED;
  }

  static isPlanEsimUninstalled(plan) {
    const isActive = this.isPlanActive(plan);

    return isActive && this.isEsimStatusUninstalled(plan.esim_status);
  }
}
