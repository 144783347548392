// ERROR MESSAGE
export const getErrorMessageContent = (state) => state.error.message;
export const getErrorMessageOpen = (state) => state.error.open;

// SHOPPING CART
export const getShoppingCart = (state) => state.shopping.cart;

// USER
export const hasUserCompleteProfile = (state) => state.user.hasCompletedProfile;
export const getUserProfile = (state) => {
  const { profile } = state.user;
  const isEqualToEmail = profile.firstName === undefined || profile.firstName === profile.email;

  if (isEqualToEmail) {
    return {
      ...profile,
      firstName: '',
      lastName: ''
    };
  }

  return profile;
};

export const getUserPlans = (state) => state.user.plans;
