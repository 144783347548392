import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { KeycloakProvider } from './auth';
import init from './config';
import './config/i18n';
import Routes from './config/routes';
import * as serviceWorker from './serviceWorker';
import Seo from './ui/Seo';
import SnackbarProvider from './ui/snackbar/snackbarProvider';
import ThemeProvider from './ui/theme/themeProvider';

const { store, keycloak, ErrorBoundary } = init();
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <KeycloakProvider config={keycloak}>
        <Provider store={store}>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CssBaseline />
              <Router>
                <SnackbarProvider>
                  <Seo />
                  <Routes />
                </SnackbarProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Provider>
      </KeycloakProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
