import React from 'react';
import { Route } from 'react-router-dom';
import { Hidden } from '@material-ui/core';

import MainLayout from '../../ui/layout/MainLayout';

export default function PublicRoute({ hideMenu, isDetail, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isDetail ? (
          <>
            <Hidden smUp>
              <Component {...props} />
            </Hidden>
            <Hidden only="xs">
              <MainLayout>
                <Component {...props} />
              </MainLayout>
            </Hidden>
          </>
        ) : (
          <MainLayout hideMenu={hideMenu}>
            <Component {...props} />
          </MainLayout>
        )
      }
    />
  );
}
