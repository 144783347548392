import HeronSansWoff2 from '../fonts/HeronSansCondBold.woff';

const heron = {
  fontFamily: 'HeronSansCond',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('HeronSansCond'),
    local('HeronSansCondBold'),
    url('${HeronSansWoff2}') format('woff2')
  `
};

export default [heron];
