import React from 'react';
import PropTypes from 'prop-types';

import { Type, Flexbox } from "..";

import { ECard } from './styled';

const EWatchCard = (props) => {
  const { selectedPlan } = props;

  const onClickCardAction = () => {
    if (props.onClickCard) props.onClickCard(props.index);
  };

  return (
    <ECard selected={selectedPlan} onClick={onClickCardAction}>
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="column" verticalMarginSwell={1}>
          <Type.H4 color={selectedPlan ? "blue1" : "blue5"}>
            {props.title}
          </Type.H4>
          <Type.P caps color="blue5">
            <b>{props.subtitle}</b>
          </Type.P>
          <Type.P caps color="blue5">
            <b>{props.type}</b>
          </Type.P>
        </Flexbox>
        <Flexbox flexDirection="column" verticalMarginSwell={1}>
          {props.message.map((string) => (
            <Type.P color="grey9" key={string}>
              {string}
            </Type.P>
          ))}
        </Flexbox>
      </Flexbox>
    </ECard>
  );
};

EWatchCard.defaultProps = {
  title: 'Unlimited',
  subtitle: 'GB / voice / SMS',
  message: ['7 days trial', 'cancel anytime'],
  price: '0.00$/month',
};

EWatchCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.array,
  price: PropTypes.string,
  selectedPlan: PropTypes.bool,
  unit: PropTypes.string
};

export default EWatchCard;
