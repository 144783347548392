import { combineReducers } from 'redux';

import { errorReducer } from './error';
import { shoppingReducer } from './shopping';
import { userReducer } from './user';

export default combineReducers({
  error: errorReducer,
  shopping: shoppingReducer,
  user: userReducer
});
