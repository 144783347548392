import { types } from './actions';

const INITIAL_STATE = {
  hasCompletedProfile: null,
  profile: {},
  plans: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_FILLED_PROFILE_FLAG:
      return {
        ...state,
        hasCompletedProfile: action.payload
      };
    case types.UPDATE_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      };
    case types.UPDATE_PLANS:
      return {
        ...state,
        plans: action.payload
      };
    default:
      return state;
  }
};
