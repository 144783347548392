import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { toggleErrorDialog } from '../../../redux/error/actions';
import { getErrorMessageContent, getErrorMessageOpen } from '../../../redux/selectors';

const ErrorMessageDialog = () => {
  const { t } = useTranslation('');

  const content = useSelector(getErrorMessageContent);
  const open = useSelector(getErrorMessageOpen);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleErrorDialog(undefined));
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        {t('somethingWentWrong')}
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorMessageDialog;
