/* eslint-disable react/no-array-index-key,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { theme } from '../../theme/theme';
import Typo from '../typo/CustomTypograph';
import routes from '../../../constants/routes';

import Type from './Typography';
import Spacer from './Spacer';
// Deprecated. Use MUI instead

const StyleDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-top: ${(props) => (props.mt ? props.mt : theme.size.swell(3))};
  @media (max-width: 640px) {
    max-width: 292px;
    margin: auto;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;

    .title {
      margin: 0px;
    }
    .main {
      position: relative;
      text-align: center;
      width: 100%;

      .visible {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: ${theme.size.swell(5)};
        box-shadow: ${theme.shadows[3]};
        border: none;
        border-radius: 3px;
        background-color: ${theme.colors.white};

        &.error {
          border: 1px solid #ff0000;
        }

        .selected {
          padding: 0 ${theme.size.swell(1)};
        }

        .icon {
          margin: 0px ${theme.size.swell(2)};

          svg {
            width: 12px;
            height: 12px;
            transform: ${(props) => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
            transition: transform 0.2s ease-out;
            vertical-align: middle;
          }
        }
      }

      .content {
        display: inline-block;
        position: absolute;
        transform: translate(-50%, 0%);
        width: 100%;
        max-height: ${theme.size.swell(49)};
        overflow-y: auto;
        margin-top: 0;
        border-radius: ${theme.size.borderRadius};
        background-color: ${theme.colors.white};
        z-index: 3;
        box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.08), 0 15px 30px 0 rgba(0, 0, 0, 0.05);
      }
    }
  }
`;

const StyleLabel = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.size.swell(1)};
  background-color: ${(props) => (props.selected ? theme.colors.grey2 : theme.colors.white)};
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.grey2};
  }
`;
const StyleFlag = styled.div`
  display: inline-flex;
  width: 24px;
  height: 18px;
  vertical-align: text-bottom;
  margin-right: 0.5625em;
  img {
    max-width: 100%;
  }
`;

const LocationIcon = styled.div`
  display: inline-flex;
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  margin-right: 0.5625em;
  img {
    max-width: 100%;
  }
`;

const LabelList = (props) =>
  !props.data
    ? null
    : props.data.map((obj, objIndex) => {
        const { onClickContentAction, link } = props;

        let Wrapper = React.Fragment;
        const wrapperProps = { key: obj.code };

        if (link && !obj.skip) {
          wrapperProps.to = `${routes.PLANS}/${obj.code}`;
          Wrapper = Link;
        }

        const WrapperIcon = obj.code.toLowerCase() === 'location' ? LocationIcon : StyleFlag;

        return (
          // eslint-disable-next-line react/jsx-key
          <Wrapper {...wrapperProps}>
            <StyleLabel
              key={objIndex}
              selected={props.selectedIndex === objIndex}
              onClick={() => (onClickContentAction ? onClickContentAction(objIndex) : {})}
            >
              {props.flag && (
                <WrapperIcon>
                  <img src={`/assets/svg-flags/${obj.code.toLowerCase()}.svg`} alt="Flag" />
                </WrapperIcon>
              )}
              <Type.P>{obj.label}</Type.P>
            </StyleLabel>
          </Wrapper>
        );
      });

const DropdownCore = (props) => {
  const { list, flag, defaultSelectedIndex, onClickDropdown } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);

  const obj = list[selectedIndex];
  const WrapperIcon = obj.code.toLowerCase() === 'location' ? LocationIcon : StyleFlag;

  useEffect(() => {
    setSelectedIndex(defaultSelectedIndex);
  }, [defaultSelectedIndex]);

  return (
    <StyleDropdown isOpen={props.isOpen} mt={props.mt}>
      <div className="left">
        <div className="title">
          <Spacer>
            <Type.H5>{props.title}</Type.H5>
          </Spacer>
        </div>
        <div
          className="main"
          onClick={() => {
            if (!props.disabled && typeof onClickDropdown === 'function') {
              onClickDropdown();
            }
          }}
        >
          <div className={`visible ${props.error ? 'error' : ''}`}>
            <div className="selected">
              {flag && (
                <WrapperIcon>
                  <img src={`/assets/svg-flags/${obj.code.toLowerCase()}.svg`} alt="Flag" />
                </WrapperIcon>
              )}
              {selectedIndex !== undefined && selectedIndex >= 0 && obj.label}
            </div>
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M1.636 20c-.419 0-.836-.154-1.156-.462a1.54 1.54 0 0 1 0-2.233L8.05 10 .48 2.695a1.538 1.538 0 0 1 0-2.232 1.678 1.678 0 0 1 2.314 0l8.727 8.42a1.54 1.54 0 0 1 0 2.234l-8.727 8.421c-.32.308-.74.462-1.158.462"
                />
              </svg>
            </div>
          </div>
          {props.isOpen && (
            <div className="content">
              <LabelList
                selectedIndex={selectedIndex}
                data={list}
                link={props.link}
                flag={flag}
                onClickContentAction={(i) => {
                  setSelectedIndex(i);
                  if (props.onClickContentAction) props.onClickContentAction(i);
                }}
              />
            </div>
          )}
        </div>
        <Box mt={0.5} mb={0.5}>
          {props.error && (
            <Typo variant="caption" color="error">
              {props.errorMessage}
            </Typo>
          )}
        </Box>
      </div>
    </StyleDropdown>
  );
};

DropdownCore.defaultProps = {
  title: 'Default Title',
  link: false,
  flag: false,
  defaultSelectedIndex: 0,
  list: [{ label: 'Default Label 0' }, { label: 'Default Label 1' }, { label: 'Default Label 2' }]
};

DropdownCore.propTypes = {
  title: PropTypes.string,
  link: PropTypes.bool,
  flag: PropTypes.bool,
  defaultSelectedIndex: PropTypes.number,
  list: PropTypes.array,
  isOpen: PropTypes.bool,
  onClickDropdown: PropTypes.func
};

// Outside click logic from reactjs.org documentation
class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.toggleContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
    window.addEventListener('touchstart', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
    window.removeEventListener('touchstart', this.onClickOutsideHandler);
  };

  onClickHandler() {
    this.setState((currentState) => ({ isOpen: !currentState.isOpen }));
  };

  onClickOutsideHandler(event) {
    if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    return (
      <div ref={this.toggleContainer}>
        <DropdownCore {...this.props} isOpen={this.state.isOpen} onClickDropdown={ () => this.onClickHandler()} />
      </div>
    );
  }
}

export default Dropdown;
