import React from 'react';
import styled from 'styled-components';

import { theme } from '../../theme/theme';

const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;

const random = [];
const top = () => `${getRandomArbitrary(22, 32)  }%`;
const left = () => `${getRandomArbitrary(-16, 118)  }%`;

random.push([top(), left()]);
random.push([top(), left()]);
random.push([top(), left()]);
random.push([top(), left()]);
random.push([top(), left()]);

const Frame = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  margin: 0;
  top: 0px;

  @viewport {
    width: device-width;
    height: device-height;
    zoom: 1.0;
  }

  @media (max-width: ${theme.size.mediaQueries.mobileXL}) {
    .desktop {
      display: none;
    }
  }
`;

const RandomPosition = styled.div`
  svg {
    position: absolute;
    width: ${props => props.width};
    height: ${props => props.height};
    top: ${props => random[props.i][0]};
    left: ${props => random[props.i][1]};
  }
`;

const FixedPosition = styled.div`
  top: 180px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  svg {
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;

const Blob = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="135"
      viewBox="0 0 140 135"
    >
      <path
        fill="#299DC1"
        fillRule="evenodd"
        d="M45.92 2.875c-13.987 4.9-25.926 16.066-34.717 33.498l-2.8 5.554C-8.978 76.395.731 106.247 36.357 123.04l9.037 4.261c34.058 16.056 65.212 7.231 83.31-28.661l2.802-5.555c17.547-34.797 7.753-64.28-27.954-81.113l-9.037-4.259C77.167-.467 60.758-2.139 46.457 2.69l-.537.185z"
      />
    </svg>
  );

const RandomBackground = () => (
    <Frame>
      <RandomPosition width='140px' height='135px' i={0}>
        <Blob />
      </RandomPosition>
      <RandomPosition width='280px' height='270px' i={1}>
        <Blob />
      </RandomPosition>
      <FixedPosition width='420px' height='405px' i={2}>
        <Blob />
      </FixedPosition>
      <div className='desktop'>
        <RandomPosition width='140px' height='135px' i={3}>
          <Blob />
        </RandomPosition>
        <RandomPosition width='280px' height='270px' i={4}>
          <Blob />
        </RandomPosition>
      </div>
    </Frame>
  );

export default RandomBackground;
