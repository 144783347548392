import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        maxWidth: (props) => props.maxWidth,
        width: '100%',
        color: (props) => props.color,
    },
    content: {
        padding: theme.spacing(3),
    }
}));

const TextCard = ({ children, ...props }) => {
    const classes = useStyles(props);

    return (
        <Card raised className={classes.root} {...props}>
            <CardContent className={classes.content}>
                {children}
            </CardContent>
        </Card>
    )
};

TextCard.propTypes = {
    children: PropTypes.node,
}

export default TextCard;