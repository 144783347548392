import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Cancel from '@material-ui/icons/Cancel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typo from '../typo/CustomTypograph';

import mask from './mask';
import validator from './validator';

const useStyles = makeStyles((theme) => ({
  animatedSlideDownParent: {
    position: 'relative'
  },
  animatedSlideDown: {
    // position: 'absolute',
    marginBottom: theme.spacing(0.5),
    animation: `$slideDown 500ms ease-in-out`
  },
  '@keyframes slideDown': {
    '0%': {
      opacity: 0,
      transform: 'translate(0%, -100%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0%, 0%)'
    }
  },
  notchedOutline: {
    borderColor: theme.colors.grey5
  },
  label: {
    color: `${theme.colors.blue5}!important`
  }
}));

const Input = (props) => {
  const { type, defaultValue, fieldProps } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [constants, setConstants] = useState(null);

  const accessConstants = (key) => (constants === null ? '' : constants[key]);

  const saveTranslations = () => {
    const output = {};
    const keyList = ['title', 'subtitle', 'placeholder', 'message', 'emptyMessage'];
    keyList.forEach((key) => {
      output[key] = t(props[key]);
    });
    setConstants(output);
  };

  useEffect(() => {
    if (constants === null) saveTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue && defaultValue !== '') {
      setEmpty(false);
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    setEmpty(props.empty);
  }, [props.empty]);

  useEffect(() => {
    if (value !== '') setEmpty(false);

    if (typeof props.updateForm === 'function') {
      props.updateForm({
        id: props.id,
        value
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const runValidator = (newValue, done = false) => {
    let isValid = false;
    // Support component custom validator (which overrides the 'type' validator)
    if (typeof props.validator === 'function') {
      isValid = props.validator(newValue);
    } else {
      isValid = validator({ value: newValue, type, done }).valid;
    }

    if (isValid) {
      setValue(newValue);
    }
  };

  const update = (event) => {
    let newValue = event.target.value;

    if (newValue === ' ') return;

    const uppercaseOnlyTypes = ['passport'];
    if (uppercaseOnlyTypes.includes(type)) newValue = newValue.toUpperCase();

    if (mask.addMaskTypesList.includes(type)) {
      newValue = mask.run(type, newValue, value);
    }

    runValidator(newValue, false);
  };

  return (
    <>
      <TextField
        {...fieldProps}
        fullWidth
        variant="outlined"
        label={accessConstants('title')}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
            root: classes.input,
            error: classes.textFieldError
          },
          endAdornment: type === 'eid' && (
            <InputAdornment position="end">
              {value ? (
                <IconButton aria-label="clear field" onClick={() => setValue('')}>
                  <Cancel />
                </IconButton>
              ) : (
                ''
              )}
            </InputAdornment>
          )
        }}
        value={value}
        type={type === 'password' && showPassword ? 'text' : type === 'password' ? 'password' : 'text'}
        placeholder={accessConstants('placeholder')}
        disabled={props.disabled}
        error={error}
        onChange={update}
        endadornment={
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
      {props.message && !error && !empty && (
        <Box mt={0.5}>
          <Typo variant="caption" component="body" color="textSecondary">
            {accessConstants('message')}
          </Typo>
        </Box>
      )}
      <Box className={classes.animatedSlideDownParent}>
        {empty && (
          <Typo className={classes.animatedSlideDown} variant="caption" color="error">
            {accessConstants('emptyMessage')}
          </Typo>
        )}
        {error && !empty && (
          <Typo className={classes.animatedSlideDown} variant="caption" color="error">
            {t(props.errorMessage)}
          </Typo>
        )}
      </Box>
    </>
  );
};

Input.defaultProps = {
  type: 'text',
  defaultValue: '',
  title: 'Default title',
  subtitle: '',
  placeholder: '',
  message: '',
  error: false,
  errorMessage: '',
  empty: false,
  emptyMessage: 'Empty input',
  disabled: false
};

Input.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  empty: PropTypes.bool,
  emptyMessage: PropTypes.string,
  disabled: PropTypes.bool,
  titleVariant: PropTypes.string,
  updateForm: PropTypes.func,
  fieldProps: PropTypes.object
};

export default Input;
export const useInputStyles = useStyles;
