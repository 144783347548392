import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.35)'
  },
  paper: {
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      overflowX: (props) => props.noHorizontalScroll && 'hidden'
    }
  },
  paperStyle: {
    [theme.breakpoints.down('sm')]: {
      width: (props) => props.controledWidth && 350,
      alignSelf: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      width: (props) => props.controledWidth && 600
    }
  },
  closeButtonContainer: {
    display: 'inline-table',
    position: 'fixed',
    width: 30,
    height: 30,
    right: 50,
    top: 30,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 30
    }
  },
  closeButton: {
    color: theme.colors.white,
    [theme.breakpoints.down('xs')]: {
      color: theme.colors.black
    }
  },
  nonPadded: {
    padding: 0,
  }
}));

export default useStyles;
