import React, { Suspense } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

function SeoInner() {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{t('seo.title')}</title>
      <meta name="description" content={t('seo.description')} />
      <meta property="og:title" content={t('seo.title')}></meta>
      <meta property="og:description" content={t('seo.description')} />
    </Helmet>
  );
}

function Seo() {
  return (
    <Suspense fallback={<></>}>
      <SeoInner />
    </Suspense>
  );
}

export default Seo;
