import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { env } from './utils/getEnv';

export default () => {
  Bugsnag.start({
    apiKey: env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: env.REACT_APP_ENV,
    appVersion: env.REACT_APP_VERSION,
    onError: (event) => {
      event.addMetadata('screen', {
        ...window.screen,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      })
    }
  });

  return Bugsnag.getPlugin('react').createErrorBoundary(React);
};
