import styled from 'styled-components';

import BaseText from './styled';
// Deprecated. Use MUI Typography instead

const createThemedStyle = ({ theme, size }) => {

  const sizes = {
    regular: {
      fontSize: theme.fontSizes.font_50,
      lineHeight: theme.lineHeight.line_50,
    }
  };

  return sizes[size];
};

const NumberLabel = styled(BaseText)`
  ${createThemedStyle};
`;

NumberLabel.defaultProps = {
  size: 'regular'
};

export default NumberLabel;
