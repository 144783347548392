import styled from 'styled-components';

import { theme } from '../../theme/theme';
// Deprecated. Use MUI instead

const main = `
  margin: 0px;
  padding: 0px;
  font-weight: 400;

  text-decoration: none;
  -webkit-font-smoothing: antialiased;

  b {
    font-weight: 700;
    ::selection {
      color: ${theme.colors.white};
      background: ${theme.colors.blue1};
    }
  }
  ::selection {
    color: ${theme.colors.white};
    background: ${theme.colors.blue1};
  }
`;
// Deprecated. Use MUI instead

const fallback = ', Helvetica, Arial, sans-serif';
const fontFamily = (props) => (props.heron ? `HeronSansCond${fallback}` : `Inter${fallback}`);
const color = (props) => (props.white ? theme.colors.white : props.color ? theme.colors[props.color] : theme.colors.black);
const textTransform = (props) => (props.caps ? 'uppercase' : 'none');
const textAlign = (props) => (props.textAlign ? props.textAlign : 'center');
// Deprecated. Use MUI instead
const H1 = styled.h1`
  ${main};
  font-size: ${theme.typography.config.h1};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H2 = styled.h2`
  ${main};
  font-size: ${theme.typography.config.h2};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H3 = styled.h3`
  ${main};
  font-size: ${theme.typography.config.h3};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H4 = styled.h4`
  ${main};
  font-size: ${theme.typography.config.h4};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H5 = styled.h5`
  ${main};
  font-size: ${theme.typography.config.h5};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H6 = styled.h6`
  ${main};
  font-size: ${theme.typography.config.h6};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const P = styled.p`
  ${main};
  font-size: ${theme.typography.config.p};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const H2SmallCaps = styled.span`
  ${main};
  font-size: ${theme.typography.config.h2SmallCaps};
  font-family: ${fontFamily};
  color: ${color};
  text-transform: ${textTransform};
  text-align: ${textAlign};
`;
// Deprecated. Use MUI instead
const Type = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  H2SmallCaps
};

export default Type;
// Deprecated. Use MUI instead
