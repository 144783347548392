import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SelectComponent from './SelectComponent';

const StyleFlag = styled.div`
  display: inline-flex;
  width: 24px;
  height: 18px;
  vertical-align: text-bottom;
  margin-right: 0.5625em;
  img {
    max-width: 100%;
  }
`;

const Flag = ({ countryCode }) => (
  <StyleFlag>
    <img src={`/assets/svg-flags/${countryCode}.svg`} alt="Flag" />
  </StyleFlag>
);

const placeholderCode = 'placeholder';

const Option = ({ value, isoCode }) => (
  <>
    <Flag countryCode={isoCode === placeholderCode ? 'location' : isoCode.toLowerCase()} />
    {value}
  </>
);

const CountrySelectComponent = ({ countries, hasPlaceholder, ...props }) => {
  const { t } = useTranslation();

  const createItems = () => {
    const items = hasPlaceholder ? [placeholderCode, ...countries] : countries;

    return items.map((isoCode) => {
      const isSelectPlaceholder = isoCode === placeholderCode;

      return {
        code: isoCode,
        label: <Option value={isSelectPlaceholder ? props.placeholder : t(`countries.${isoCode}`)} isoCode={isoCode} />
      };
    });
  };

  const elementsList = Array.isArray(countries) && countries.length > 0 && createItems();

  return <SelectComponent data={elementsList} bgcolor="#f7f6f6" {...props} />;
};

CountrySelectComponent.defaultProps = {
  selected: 'placeholder',
  onSelectFunction: () => {},
  hasPlaceholder: false
};

CountrySelectComponent.propTypes = {
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
    })
  ),
  onSelectFunction: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasPlaceholder: PropTypes.bool
};

export default CountrySelectComponent;
