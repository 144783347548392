import { createMuiTheme } from '@material-ui/core';

import typography from './typography';
import colors from './colors';
import shadows from './shadows';
import overrides from './overrides';
import size from './size';

export const theme = {
  typography,
  colors,
  shadows,
  size
};

const muiTheme = {
  spacing: 8,
  size,
  typography,
  colors,
  palette: {
    primary: { main: colors.blue1, contrastText: colors.white },
    secondary: { main: colors.secondary, contrastText: colors.white },
    error: { main: colors.error, contrastText: colors.white },
    success: { main: colors.success, contrastText: colors.white },
    planStatus: { active: colors.green1, notActive: colors.grey9, setupEsim: colors.orange1 },
    profileVerification: { verified: colors.blue1, notVerified: colors.error },
    text: {
      primary: colors.blue5,
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      light: colors.white
    },
    background: colors.cream
  },
  shadows,
  overrides: overrides()
};

export default createMuiTheme(muiTheme);
