import { types } from './actions';

const INITIAL_STATE = {
  open: false,
  message: undefined
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TOGGLE_DIALOG:
      return {
        ...state,
        open: !state.open,
        message: action.payload
      };

    default:
      return state;
  }
};
