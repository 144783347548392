import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = (props) => {
  const {thickness = 5, size = 20} = props;
  return (
    <CircularProgress thickness={thickness} size={size} color="inherit"/>
  );
};

Loading.propTypes = {
  thickness: PropTypes.number,
  size: PropTypes.number,
};

export default Loading;
