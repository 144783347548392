import configureStore from '../redux/store';

import keycloak from './keycloak';
import configureBugsnag from './bugsnag';
// import facebookInit from './facebookInit';
import gtmInit from './gtmInit';

const init = () => {
  // facebookInit();
  gtmInit();

  return {
    store: configureStore(),
    ErrorBoundary: configureBugsnag(),
    keycloak: keycloak()
  };
};

export default init;
