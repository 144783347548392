import regex from './config';
import { SUPPORTED_CARDS, SUPPORTED_CURRENCIES_BY_CARD } from './constants';
// import test from './tests';

export function isUser18orMoreYearsOld(value) {
  const year = new Date(value).getFullYear();
  const month = new Date(value).getMonth();
  const day = new Date(value).getDate();
  return new Date(year + 18, month, day) <= new Date();
}

const checkCardSystemType = (creditCardNumber) => {
  const ccNumber = creditCardNumber.replace(/\s+/g, '');
  if (regex.visa.test(ccNumber)) return 'visa';
  if (regex.mastercard.test(ccNumber)) return 'masterCard';
  if (regex.americanExpress.test(ccNumber)) return 'americanExpress';
  return '';
};

const supportedCardSystemsValidator = (cardMake, currency) => {
  if (cardMake && currency) {
    const isCardTypeValid = SUPPORTED_CURRENCIES_BY_CARD[cardMake].includes(currency);
    return isCardTypeValid;
  }
  return true;
};

// Utils
const injectValid = (obj, booleanResult) => {
  obj.valid = booleanResult;
  return obj;
};

const injectErrorMessage = (obj, errorMessage) => {
  obj.errorMessage = errorMessage;
  return obj;
};

const getErrorMessage = (cardBrand, isCardValid) => {
  if (!cardBrand) return 'checkout.cardInputs.0.errorMessage';
  if (!isCardValid) return 'checkout.cardInputs.0.notSupportedCard';
  return 'checkout.cardInputs.0.errorMessage';
};

const testRegexByKey = (value, key) => regex[key].test(value);

const mask = (value, key) => value === '' || testRegexByKey(value, key);

const expirationDateGreaterThanToday = (value) => {
  const d = new Date();
  const m = d.getMonth();
  const yy = d.getFullYear().toString();
  const valueList = value.split('/').map((v) => Number(v));
  // months in JS start at 0 so we must always subtract 1 from the input date
  const monthValid = valueList[1] === Number(yy.slice(-2)) ? valueList[0] - 1 >= m : true;
  const yearValid = valueList[1] >= Number(yy.slice(-2));

  return monthValid && yearValid;
};

// List of validation function by type
const validationFunctions = {
  all: (obj) => {
    const result = obj.done ? regex.all.test(obj.value) && obj.value !== '' : regex.all.test(obj.value);
    return injectValid(obj, result);
  },

  numbers: (obj) => {
    const result = obj.done ? regex.numbers.test(obj.value) && obj.value !== '' : regex.numbers.test(obj.value);
    return injectValid(obj, result);
  },

  noNumbers: (obj) => {
    const result = obj.done ? regex.noNumbers.test(obj.value) && obj.value !== '' : regex.noNumbers.test(obj.value);
    return injectValid(obj, result);
  },

  text: (obj) => injectValid(obj, regex.text.test(obj.value)),

  textOnly: (obj) => {
    const result = obj.done ? regex.textOnly.test(obj.value) && obj.value !== '' : regex.textOnly.test(obj.value);
    return injectValid(obj, result);
  },

  name: (obj) => {
    const result = obj.done ? regex.textOnly.test(obj.value) && obj.value !== '' : mask(obj.value, 'textOnly');
    return injectValid(obj, result);
  },

  fullName: (obj) => {
    const result = obj.done ? regex.fullName.test(obj.value) && obj.value !== '' : mask(obj.value, 'text');
    return injectValid(obj, result);
  },

  password: (obj) => {
    const result = obj.done ? regex.password.test(obj.value) : mask(obj.value, 'all');
    return injectValid(obj, result);
  },

  email: (obj) => {
    const result = obj.done ? regex.email.test(obj.value) : regex.emailMask.test(obj.value);
    return injectValid(obj, result);
  },

  expirationDate: (obj) => {
    const result = obj.done
      ? regex.expirationDate.test(obj.value) && expirationDateGreaterThanToday(obj.value)
      : regex.expirationDateMask.test(obj.value);
    return injectValid(obj, result);
  },

  cvv: (obj) => {
    const result = obj.done ? regex.cvv.test(obj.value) : regex.cvvMask.test(obj.value);
    return injectValid(obj, result);
  },

  cardNumber: (obj) => {
    const cardBrand = checkCardSystemType(obj.value);
    const isCardSupported = SUPPORTED_CARDS.includes(cardBrand);
    const doesCardSupportCurrency = supportedCardSystemsValidator(cardBrand, obj.currency);
    const isCardValid = isCardSupported && doesCardSupportCurrency;

    const errorMessage = getErrorMessage(cardBrand, isCardValid);
    obj = injectErrorMessage(obj, errorMessage);

    const result = obj.done ? isCardValid : regex.cardNumberMask.test(obj.value);
    return injectValid(obj, result);
  },

  eid: (obj) => {
    const result = obj.done ? regex.eid.test(obj.value) : regex.eidMask.test(obj.value);
    return injectValid(obj, result);
  },

  dateYMD: (obj) => {
    const result = obj.done ? regex.dateYMD.test(obj.value) && isUser18orMoreYearsOld(obj.value) : regex.dateYMDMask.test(obj.value);
    return injectValid(obj, result);
  },

  dateDMY: (obj) => {
    const result = obj.done ? regex.dateDMY.test(obj.value) : regex.dateDMYMask.test(obj.value);
    return injectValid(obj, result);
  },

  passport: (obj) => {
    const result = obj.done ? regex.passport.test(obj.value) : regex.passportMask.test(obj.value);
    return injectValid(obj, result);
  },

  dni: (obj) => {
    const result = obj.done ? regex.dni.test(obj.value) : regex.dniMask.test(obj.value);
    return injectValid(obj, result);
  },

  pesel: (obj) => {
    const result = obj.done ? regex.pesel.test(obj.value) : regex.peselMask.test(obj.value);
    return injectValid(obj, result);
  },

  usaZipCode: (obj) => {
    const result = obj.done ? regex.usaZipCode.test(obj.value) : regex.usaZipCodeMask.test(obj.value);
    return injectValid(obj, result);
  }
};

const validationTypeNumber = (obj) => {
  const customLengthAllRegex = new RegExp(`^.{0,${obj.type}}$`);
  const result = obj.done ? customLengthAllRegex.test(obj.value) && obj.value !== '' : customLengthAllRegex.test(obj.value);
  return injectValid(obj, result);
};

// Core validation switch
const validator = (obj) => {
  // If type is a number generate a regex rule where the number is the max length
  if (typeof obj.type === 'number' && !Number.isNaN(Number(obj.type))) {
    return validationTypeNumber(obj);
  }

  // If type is a key of validationFunctions list
  if (typeof validationFunctions[obj.type] === 'function') {
    return validationFunctions[obj.type](obj);
  }
  // Default
  console.warn(
    `This type of input does not have any validator function. You should create one validator function inside of validationFunctions or use one of this list: [${Object.keys(
      validationFunctions
    ).join(', ')}]`
  );
  return injectValid(obj, false);
};

export default validator;

// Run tests
// test();
