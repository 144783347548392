import React from 'react';
import { Box } from '@material-ui/core';

import CustomTypograph from '../typo/CustomTypograph';
import colors from '../../theme/colors';

const Tag = (props) => (
  <Box bgcolor={colors.blue1} borderRadius={4} alignItems="center" paddingY={1} paddingX={2}>
    <CustomTypograph variant="body2LightBold">
      {props.text} / {props.period}
    </CustomTypograph>
  </Box>
);

export default Tag;
