import React from 'react';
import { Button as MaterialButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Loading } from '../loading';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1.5, 3),
    maxHeight: theme.spacing(5),
    minWidth: theme.spacing(17),
  }
}));

const Button = (props) => {
  const {
    color = 'primary',
    variant = 'contained',
    onClick = undefined,
    loading = false,
    children,
    'data-testid': dataTestId,
  } = props;

  const classes = useStyles();

  const handleClick = () => {
    if(!loading && onClick) {
      onClick();
    }
  };

  return (
    <MaterialButton color={color}
                    variant={variant}
                    className={classes.button}
                    onClick={handleClick}
                    data-testid={dataTestId}>
      {
        loading ? <Loading/> : children

      }
    </MaterialButton>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
}

export default Button;
