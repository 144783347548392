// We are using sessionStorage when it is needed to pass information between screens
// This wrapper is just to centralize code, write less code and make sure that we are correctly using the sessionStorage.

// Save data to sessionStorage by key and value
const set = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// Get saved data from sessionStorage by key
const get = (key) => JSON.parse(sessionStorage.getItem(key));

// Remove saved data from sessionStorage by key
const remove = (key) => {
  sessionStorage.removeItem(key);
};

// We should avoid to use sessionStorage.clear(), because it can make some bugs.
// sessionStorage.clear() should only be used when we have sure that we want to delete all the app user data.

const session = {
  set,
  get,
  remove
};

export default session;
