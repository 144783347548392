import styled from 'styled-components';

import { theme } from '../../theme/theme';

const Spacer = styled.div`
  margin: ${props => `${theme.size.swell(props.verticalMarginSwell)  } 0px`};
`;

Spacer.defaultProps = {
  verticalMarginSwell: 2 // 16px // margin: 16px 0px;
};

export default Spacer;
