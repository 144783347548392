import styled from 'styled-components';

import BaseText from './styled';
// Deprecated. Use MUI Typography instead

const createThemedStyle = ({ theme, size }) => {

  const sizes = {
    regular: {
      fontSize: theme.fontSizes.font_16,
    },
    small: {
      fontSize: theme.fontSizes.font_14,
    },
    xSmall: {
      fontSize: theme.fontSizes.font_10,
    },
  };

  return sizes[size];
};

const StyledText = styled(BaseText)`
  ${createThemedStyle};
`;

StyledText.defaultProps = {
  size: 'regular'
};

export default StyledText;
// Deprecated. Use MUI Typography instead
