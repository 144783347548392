import React from 'react';
import { string, node, oneOfType, number, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box } from '@material-ui/core';

import AppLoading from '../appLoading';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    marginTop: (props) => (props.isDetail ? 40 : 0),
    paddingTop: 0,

    [theme.breakpoints.down('md')]: {
      maxWidth: 'none'
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: (props) => (props.isDetail ? 10 : 0)
    }
  },
  root: {
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const PageContainer = (props) => {
  const { isDetail, header, loading, children, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      {props.header}
      <Container className={classes.container}>
        <Grid container {...rest}>
          {loading ? <AppLoading /> : props.children}
        </Grid>
      </Container>
    </Box>
  );
};

PageContainer.propTypes = {
  direction: string,
  justify: string,
  alignItems: string,
  spacing: oneOfType([string, number]),
  header: node,
  pt: number,
  isDetail: bool,
  loading: bool
};

PageContainer.defaultProps = {
  direction: 'column',
  justify: 'flex-start',
  spacing: 0,
  isDetail: false,
  loading: false
};

export default PageContainer;
