import { useState } from 'react';
import Bugsnag from '@bugsnag/js';

import api from '../config/api';

const INVENTORY_URL = '/v1/my-plans';

const useFetchMyPlans = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataProduct, setDataProduct] = useState(null);
  const [dataHistory, setDataHistory] = useState(null);

  const getMyPlanById = async (id) => {
    try {
      setLoading(true);
      const url = id ? `${INVENTORY_URL}/${id}` : INVENTORY_URL;
      const { data } = await api.requestGET(url);
      setDataProduct(data);
    } catch (e) {
      Bugsnag.notify(e);
      if (e.response) {
        setError(e.response.status);
      } else {
        setError(500);
      }
    } finally {
      setLoading(false);
    }
  };

  const getMyPlans = async () => {
    try {
      setLoading(true);
      const url = INVENTORY_URL;
      const { data } = await api.requestGET(url);
      setDataProduct(data);
    } catch (e) {
      Bugsnag.notify(e);
      if (e.response) {
        setError(e.response.status);
      } else {
        setError(500);
      }
    } finally {
      setLoading(false);
    }
  };

  const getMyPlanHistoryById = async (inventoryId) => {
    try {
      setLoading(true);
      const url = `${INVENTORY_URL}/${inventoryId}/product-history`;
      const { data } = await api.requestGET(url);
      setDataHistory(data);
    } catch (e) {
      Bugsnag.notify(e);
      if (e.response) {
        setError(e.response.status);
      } else {
        setError(500);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getMyPlans,
    getMyPlanById,
    getMyPlanHistoryById,
    dataProduct,
    dataHistory,
    error,
    loading
  };
};

export default useFetchMyPlans;
