import styled from 'styled-components';

import BaseText from './styled';
// Deprecated. Use MUI Typography instead

const createThemedStyle = ({ as }) => {
  const sizes = {
    h1: {
      fontSize: '40px'
    },
    h2: {
      fontSize: '32px'
    },
    h3: {
      fontSize: '24px'
    },
    h4: {
      fontSize: '20px'
    }
  };

  return sizes[as];
};

const Title = styled(BaseText)`
  ${createThemedStyle};
`;

Title.defaultProps = {
  as: 'h1'
};

export default Title;
// Deprecated. Use MUI Typography instead
