import styled from 'styled-components';

import { theme } from '../../theme/theme';
// Deprecated. Use MUI instead

const Narrow = styled.div`
  display: flex;
  max-width: 100%;

  @media (max-width: ${theme.size.mediaQueries.mobileXL}) {
    max-width: ${theme.size.minWidth};
  }
`;

export default Narrow;
