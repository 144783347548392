import validator from './validator';

const copy = (value) => JSON.parse(JSON.stringify(value));

const findIndexById = (data, id) => copy(data).findIndex((obj) => obj.id === id);

const findDataObjById = (data, id) =>
  // Data read only
  copy(data)[findIndexById(data, id)];
const findValueObjById = (data, id) =>
  // Data read only
  findDataObjById(data, id).value;
const updateDataValue = (data, obj) => {
  const index = findIndexById(data, obj.id);
  if (index === -1) {
    console.warn(`ID not found`);
    return data;
  }

  const newData = copy(data);
  newData[index].value = obj.value;

  return newData;
};

const checkAllValidation = (data) => {
  if (!Array.isArray(data)) console.warn('First argument (data) is not an array, it should be an array of objects');

  const output = [];

  const newData = copy(data).map((obj) => {
    if (typeof obj.value === 'undefined' && obj.defaultValue !== 'undefined') obj.value = obj.defaultValue;
    if (typeof obj.value === 'undefined') obj.value = '';

    const objValidator = {
      value: obj.value,
      type: obj.type,
      done: true
    };

    if (obj.id === 'cardNumber') {
      objValidator.currency = obj.currency;
    }

    obj.valid = validator(objValidator).valid;
    output.push(obj.required ? obj.valid : true);

    if (obj.valid) {
      obj.error = false;
    } else if (obj.required && obj.value !== '') {
      obj.error = true;
      if (obj.id === 'cardNumber') {
        obj.errorMessage = validator(objValidator).errorMessage;
      }
    }

    if (obj.required && obj.value === '') obj.empty = true;
    if (obj.value !== '') obj.empty = false;

    return obj;
  });

  return {
    newData,
    isClean: !output.includes(false)
  };
};

const planCodeRebrander = (planCode, from, to) => {
  return planCode.replace(from, to);
};

const planCodeNormalizer = (planName) => {
  return planName.replace(/MY_[^_]*_/, '');
};

const utils = {
  findIndexById,
  updateDataValue,
  checkAllValidation,
  findDataObjById,
  findValueObjById,
  planCodeNormalizer,
  planCodeRebrander
};

export default utils;
