import React, { useCallback } from 'react';
import { arrayOf, bool, element, func, node, oneOfType, shape, string } from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typo from '../typo/CustomTypograph';

import DialogButtonActions from './DialogButtonActions';
import useStyles from './useDialogStyle';

const ResponsiveDialog = ({
  title,
  text,
  actions,
  content,
  ContentComponent,
  children,
  dividers,
  buttonsConfig,
  fullScreen,
  isProcessing,
  onlyOneButton,
  isLoading,
  onClose,
  padded,
  controledWidth,
  noHorizontalScroll,
  handleOpenDialog,
  dialogOpeningStatus
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ controledWidth, noHorizontalScroll });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    if (handleOpenDialog) {
      handleOpenDialog(true);
    } else {
      setOpen(true);
    }
  }, [handleOpenDialog]);

  const handleClose = useCallback(() => {
    if (!isLoading) {
      if (handleOpenDialog) {
        handleOpenDialog(false);
      } else {
        setOpen(false);
      }
      setTimeout(onClose, 300);
    }
  }, [isLoading, onClose, handleOpenDialog]);

  const isRenderActions = actions || (buttonsConfig && Array.isArray(buttonsConfig) && buttonsConfig.length);

  return (
    <>
      {typeof children === 'function' && children({ handleClickOpen })}
      <Dialog
        open={dialogOpeningStatus || open}
        fullScreen={fullScreen && isMobileScreen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{
          container: classes.container,
          paper: classes.paper
        }}
      >
        {!isRenderActions && (
          <div className={classes.closeButtonContainer}>
            <IconButton onClick={handleClose}>
              <CloseIcon className={classes.closeButton} fontSize="large" />
            </IconButton>
          </div>
        )}
        <Paper className={classes.paperStyle}>
          <DialogTitle id="responsive-dialog-title">
            <Typo align="center" variant="h4">
              {title.toUpperCase()}
            </Typo>
          </DialogTitle>
          <DialogContent className={!padded ? classes.nonPadded : ''} dividers={dividers}>
            {content}
            {ContentComponent && <ContentComponent handleClose={handleClose} />}
            {text && (
              <DialogContentText>
                <Typo variant="body1">{text}</Typo>
              </DialogContentText>
            )}
          </DialogContent>
        </Paper>
        {isRenderActions && (
          <DialogActions>
            <DialogButtonActions
              buttonsConfig={buttonsConfig}
              handleClose={handleClose}
              actions={actions}
              isProcessing={isProcessing}
              onlyOneButton={onlyOneButton}
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

ResponsiveDialog.defaultProps = {
  title: 'DIALOG TITLE',
  text: '',
  fullScreen: true,
  dividers: false,
  padded: true,
  onClose: () => {},
  noHorizontalScroll: false
};

ResponsiveDialog.propTypes = {
  title: string,
  text: string,
  actions: func,
  padded: bool,
  ContentComponent: oneOfType([node, element]),
  dividers: bool,
  buttonsConfig: arrayOf(
    shape({
      type: string,
      handleClick: func,
      color: string,
      close: bool
    })
  ),
  fullScreen: bool,
  controledWidth: bool,
  onClose: func,
  isLoading: bool,
  handleOpenDialog: func,
  dialogOpeningStatus: bool,
  noHorizontalScroll: bool,
};

export default ResponsiveDialog;
