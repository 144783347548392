import { KeyboardDatePicker } from '@material-ui/pickers';
import * as PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { formatISO, isValid as isDateValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import Typo from '../typo/CustomTypograph';

import { useInputStyles } from './Input';
import { isUser18orMoreYearsOld } from './validator';

const Datepicker = (props) => {
  const { defaultValue, id, updateForm, fieldProps } = props;

  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState(props.emptyMessage);
  const [date, setDate] = useState(defaultValue ? new Date(defaultValue) : null);

  const { t } = useTranslation();
  const classes = useInputStyles();

  useEffect(() => {
    if (date == null) setEmpty(true);
  }, [date]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    setEmpty(props.empty);
  }, [props.empty]);

  const onDateChange = (value) => {
    setDate(value);
    if (!isUser18orMoreYearsOld(value)) {
      setError(true);
      setErrorMessage('profileForm.formName.inputs.birth_date.not18yearsOld');
    } else {
      setError(false);
    }
    if (isDateValid(value)) {
      const formattedValue = formatISO(value, { representation: 'date' });
      if (formattedValue && typeof updateForm === 'function') {
        const replacedValue = formattedValue.replace(/-/g, '/');
        updateForm({ id, value: replacedValue });
      }
      setEmpty(false);
    }
  };

  return (
    <>
      <KeyboardDatePicker
        {...fieldProps}
        disableToolbar
        animateYearScrolling
        variant="inline"
        inputVariant="outlined"
        format="yyyy/MM/dd"
        id="date-picker"
        label={t(props.title)}
        placeholder={t(props.placeholder)}
        disabled={props.disabled}
        value={date}
        helperText=""
        onChange={onDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
            root: classes.input,
            error: classes.textFieldError
          }
        }}
      />
      {props.message && !error && !empty && (
        <Box mt={0.5}>
          <Typo variant="caption" component="body" color="textSecondary">
            {t(props.message)}
          </Typo>
        </Box>
      )}
      <Box className={classes.animatedSlideDownParent}>
        {empty && (
          <Typo className={classes.animatedSlideDown} variant="caption" color="error">
            {t(props.emptyMessage)}
          </Typo>
        )}
        {error && !empty && (
          <Typo className={classes.animatedSlideDown} variant="caption" color="error">
            {t(errorMessage)}
          </Typo>
        )}
      </Box>
    </>
  );
};

Datepicker.defaultProps = {
  type: 'dateYMD',
  defaultValue: '',
  title: 'Default title',
  subtitle: '',
  placeholder: '',
  message: '',
  errorMessage: '',
  emptyMessage: 'Empty input',
  disabled: false,
  error: false,
  empty: false
};

Datepicker.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  empty: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  updateForm: PropTypes.func,
  fieldProps: PropTypes.object,
  message: PropTypes.string,
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  subtitle: PropTypes.string
};

export default Datepicker;
