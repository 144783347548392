import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import FaceIcon from '@material-ui/icons/Face';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import CustomTypograph from '../../commons/typo/CustomTypograph';
import routes from '../../../constants/routes';
import { useAuth } from '../../../auth';
import useCustomerNaming from '../../../hooks/useCustomerNaming';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'white',
    width: 350,
    borderRadius: 8
  },
  manageButton: { width: '90%' },
  profileOptions: {
    width: '100%'
  },
  logout: {
    width: '100%',
    backgroundColor: theme.colors.grey1,
    padding: 15
  },
  logoutText: {
    fontSize: '14px',
    color: theme.colors.grey9
  },
  email: {
    color: theme.colors.grey9
  }
}));

const ProfilePopper = ({ popperButton }) => {
  const { t } = useTranslation('');
  const { keycloak } = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { name: userName, email: userEmail } = useCustomerNaming();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Box aria-describedby={id} onClick={handleClick}>
        {popperButton}
      </Box>

      <Popover
        classes={{
          paper: classes.paper
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Box mt={2}>
            <FaceIcon fontSize="large" color="inherit" />
          </Box>

          <CustomTypograph variant="h5" data-full-name>
            {userName}
          </CustomTypograph>

          <CustomTypograph variant="body2" className={classes.email} data-email>
            {userEmail}
          </CustomTypograph>

          <Box mt={2} mb={2} className={classes.manageButton}>
            <Link to={{ pathname: routes.PROFILE }}>
              <Button variant="contained" size="small" color="primary" fullWidth onClick={handleClose}>
                {t('profile.manage')}
              </Button>
            </Link>
          </Box>
        </Grid>

        <Divider />

        <MenuList className={classes.profileOptions}>
          <MenuItem component={Link} to={{ pathname: `${routes.PROFILE}/payment` }} onClick={handleClose}>
            <ListItemIcon color="default">
              <CreditCardIcon />
            </ListItemIcon>
            {t('profile.paymentDetails')}
          </MenuItem>
          <MenuItem component={Link} to={{ pathname: `${routes.PROFILE}/security` }} onClick={handleClose}>
            <ListItemIcon color="default">
              <VisibilityIcon />
            </ListItemIcon>
            {t('profile.security')}
          </MenuItem>
        </MenuList>

        <Button fullWidth onClick={() => keycloak.logout()} className={classes.logout}>
          <CustomTypograph variant="button" className={classes.logoutText}>
            {t('profile.logout')}
          </CustomTypograph>
        </Button>
      </Popover>
    </div>
  );
};

export default ProfilePopper;
