const colors = {
  blue1: '#1027EA',
  blue2: '#30819e',
  blue3: '#20667f',
  blue4: '#104c5f',
  blue5: '#01313f',
  blue6: '#BFC4F2',
  green: '#DBF879',
  green1: '#23A54F',
  secondary: '#ff6584',

  cream: '#f7f6f6',

  grey1: '#f3f6f6',
  grey2: '#e8ecee',
  grey3: '#dde3e5',
  grey4: '#d1d9dc',
  grey5: '#c6ccce',
  grey7: '#a1a0a1',
  grey9: '#5D5D5D',
  grey10: '#717171',

  orange1: '#f57c00',

  black: '#000000',
  white: '#ffffff',

  colorTransparent: 'rgb(16, 39, 234, .5)',
  colorTransparent1: 'rgba(16, 39, 234, .3)',
  colorTransparent2: 'rgba(0, 0, 0, .65)',

  success: '#04c453',
  error: '#e74243',
  warning: '#f57c00'
};

export default colors;
