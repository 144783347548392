const regex = {
  // All characters
  all: /^.{0,300}$/,
  // All characters, without numbers
  noNumbers: /^[^\d]*$/,
  // Only match numbers, without spaces
  numbers: /^[\d]*$/,
  // Match numbers and spaces
  numberSpaces: /^[\s\d]*$/,
  // Match all text just one word, without spaces and without numbers
  name: /^[^\s]*$/,
  // Match Text and spaces with just one space between
  fullName: /^([^\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])*[^\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]+$/,
  // Match text and spaces, without numbers and without special characters
  text: /^.{0,100}$/,
  // Only text, without spaces, without numbers and without special characters
  textOnly: /^[^\s\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]{0,100}$/,
  // Only e-mail mask
  emailMask: /^[a-zA-Z0-9@.\S]*$/,
  // Only e-mail
  email: /^.+@.+$/,
  // Match phone numbers at least 9 digits long and no more than 12 digits long + '+'
  phone: /^\+?(\d.*){9,13}$/,
  // DD/MM/YYYY date mask
  dateDMYMask: /^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/,
  // YYYY/MM/DD date mask
  dateYMDMask: /^\d{0,4}\/?\d{0,2}\/?\d{0,2}$/,
  // DD/MM/YYYY date date
  dateDMY: /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})$/,
  // YYYY/MM/DD date date
  dateYMD: /^([0-9]{4}|[0-9]{2})\/(0[1-9]|1[0-2])\/(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/,
  // Card expiration date mask
  expirationDateMask: /^[0-9/]{0,5}$/,
  // Card expiration date
  expirationDate: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
  // Match CVV (not done) with 0 to 4 digits/numbers
  cvvMask: /^\d{0,4}$/,
  // Match CVV with 3 to 4 digits/numbers
  cvv: /^\d{3,4}$/,
  // Cards by brand type // Card number mask match digits/numbers between 0 and 16
  cardNumberMask: /^(\d{0,4})(\s{0,1})$|^(\d{4})(\s{1})(\d{0,4})(\s{0,1})$|^(\d{4})(\s{1})(\d{4})(\s{01})(\d{0,4})(\s{0,1})$|^(\d{4})(\s{1})(\d{4})(\s{1})(\d{4})(\s{1})(\d{0,4})$/, // /^(\d{0,4})(\s{0,1})(\d{0,4})(\s{0,1})(\d{0,4})(\s{0,1})(\d{0,4})$/,
  mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  americanExpress: /^3[47][0-9]{13}$/,
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  maestro: /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/,
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  // EID mask match numbers and spaces, between 0 and 32 numbers or between 0 and 38 numbers + spaces
  // EID sample 89049 03200 50088 83000 03401 87859 08
  eidMask: /^(\d{0,5})(\s{0,1})(\d{0,5})(\s{0,1})(\d{0,5})(\s{0,1})(\d{0,5})(\s{0,1})(\d{0,5})(\s{0,1})(\d{0,5})(\s{0,1})(\d{0,2})$/,
  // EID match numbers and spaces, 32 numbers and 6 spaces or no spaces
  eid:/^(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{2})$/,
  // Passport number, between 1 and 4 uppercase letters + 4 to 7 numbers + optional 1 or 2 uppercase letters or 8 to 9 numbers
  passportMask: /^([A-Z]{0,3})(\d{0,7})$|^([A-Z]{1,3})(\d{4,7})([A-Z]{0,2})$|^([A-Z]{0,4})(\d{0,6})$|^\d{0,9}$/,
  passport: /^[A-Z]{1,3}[\d]{4,7}[A-Z]{0,2}$|^([A-Z]{4})(\d{5,6})$|^\d{8,9}$/,
  // Password with at least 1 lowercase, 1 uppercase, 1 number, 1 special character (!@#$%^&*()\[]{}-_+=~`|:;"'<>,./?]) and minimum length of 8 characters
  password: /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
  // DNI Number Spain Mask
  // https://ec.europa.eu/taxation_customs/tin/pdf/en/TIN_-_country_sheet_ES_en.pdf
  dniMask: /^$|^([A-Z]{0,1})(\d{0,7})$|^([A-Z]{1})(\d{7})([A-Z]{0,1})$|^(\d{0,8})([A-Z]{0,1})$/,
  // DNI Number Spain, 8 numbers + 1 uppercase letter or 1 uppercase letter + 7 numbers + 1 uppercase letter
  dni: /^([A-Z]{1})(\d{7})([A-Z]{1})$|^(\d{0,8})([A-Z]{1})$/,
  // PESEL, Poland Tax Identification Number, 10 or 11 numbers
  // https://ec.europa.eu/taxation_customs/tin/pdf/en/TIN_-_country_sheet_PL_en.pdf
  peselMask: /^[\d]{0,11}$/,
  // PESEL Mask
  pesel: /^[\d]{10,11}$/,
  // USA zip code, 5 numbers or 5 numbers + '-' + 4 numbers
  usaZipCodeMask: /^(\d{0,5})$|^(\d{5})(-{0,1})$|^(\d{5})(-{1})(\d{0,4})$/,
  usaZipCode: /^(\d{5}(?:-\d{4})?)$/
};

export default regex;
