/* eslint-disable camelcase */
import { useState } from 'react';
import Bugsnag from '@bugsnag/js';

import api from '../config/api';

import useCustomerNaming from './useCustomerNaming';

const endPoints = {
  hasCompletedProfile: {
    url: '/v1/customers/completed-profile',
    method: 'PATCH'
  },
  updateProfile: {
    url: '/v1/customers',
    method: 'PATCH'
  },
  getProfile: {
    url: '/v1/customers',
    method: 'GET'
  },
  hasFreeTrial: {
    url: '/v1/customers/free-trial',
    method: 'GET'
  }
};

const useCustomerApi = (endPoint) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [errorData, setErrorData] = useState(undefined);
  const { url, method } = endPoint;
  const { first_name, last_name } = useCustomerNaming();

  const request = async (body = {}) => {
    try {
      setLoading(true);
      setErrorData(null);
      setError(false);

      const responseData = await api[method](url, body);
      setData(responseData.data);
      return responseData;
    } catch (error) {
      if (error.response) {
        setErrorData({ status: error.response.status, msgs: error.response.data });
      }
      Bugsnag.notify(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const hasCompletedProfile = async (country_code, locale) => {
    try {
      setLoading(true);
      const body = { first_name, last_name, locale };
      const responseData = await api.PATCH(`${url}/${country_code}`, body);
      setData(responseData.data);
      setError(false);
      return responseData;
    } catch (error) {
      Bugsnag.notify(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    hasCompletedProfile,
    data,
    loading,
    error,
    errorData
  };
};

const keys = Object.entries(endPoints);
const apiCalls = keys.reduce((acc, [key, value]) => ({ ...acc, [key]: () => useCustomerApi(value) }), {});

export default apiCalls;
