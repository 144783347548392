import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import CustomTypograph from '../../../../commons/typo/CustomTypograph';

const HowItWorksIcon = () => (
  <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path fill="#00313F" d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
      <path
        fill="#00313F"
        d="M10.5 7.63V6.37l-.787-.13c-.044-.175-.132-.349-.263-.61l.481-.652-.918-.913-.657.478a2.346 2.346 0 0 0-.612-.26L7.656 3.5H6.388l-.132.783c-.219.043-.394.13-.612.26l-.657-.478-.918.913.437.652c-.131.218-.175.392-.262.61l-.744.086v1.261l.787.13c.044.218.132.392.263.61l-.438.651.92.913.655-.434c.175.086.394.173.613.26l.131.783h1.313l.131-.783c.219-.043.394-.13.613-.26l.656.478.918-.913-.48-.652c.13-.218.218-.435.262-.61l.656-.13zM7 8.283a1.285 1.285 0 0 1-1.313-1.305c0-.739.57-1.304 1.313-1.304.744 0 1.313.565 1.313 1.304 0 .74-.57 1.305-1.313 1.305z"
      />
    </g>
  </svg>
);
const HowToInstallIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="#00313F"
      strokeWidth="2"
      d="M12,18 L12,8 L12,18 Z M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M17,12 L12,7 L7,12"
      transform="matrix(1 0 0 -1 0 24)"
    />
  </svg>
);

const TermsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
    <path
      fill="#00313F"
      fillRule="evenodd"
      d="M5.327 15.12c.009 0 .016.003.025.003.01 0 .019-.003.027-.003h1.088c1.293 0 2.366 1.166 2.566 2.69.038.276-.145.523-.37.523h-6.62c-.226 0-.409-.247-.371-.522.2-1.525 1.274-2.691 2.566-2.691zm9.294 0c.01 0 .017.003.027.003.009 0 .017-.003.025-.003h1.089c1.292 0 2.366 1.166 2.566 2.69.038.276-.145.523-.37.523h-6.62c-.226 0-.409-.247-.371-.522.2-1.525 1.273-2.691 2.566-2.691zM5.352 9.152c1.282 0 2.322 1.028 2.322 2.295v.46c0 1.255-1.022 2.274-2.288 2.292h-.067c-1.266-.018-2.288-1.037-2.288-2.293v-.46c0-1.266 1.04-2.294 2.321-2.294zm9.296 0c1.281 0 2.321 1.028 2.321 2.295v.46c0 1.255-1.022 2.274-2.288 2.292h-.067c-1.266-.018-2.288-1.037-2.288-2.293v-.46c0-1.266 1.04-2.294 2.322-2.294zm3.269-7.485c.23 0 .416.186.416.416v5c0 .23-.186.417-.416.417h-5.432c-.092 0-.183.032-.257.09L9.842 9.467c-.274.216-.675.022-.675-.326V2.083c0-.23.186-.416.416-.416z"
    />
  </svg>
);

const ContactUsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#00313F"
      fillRule="evenodd"
      d="M14.231 18.217c.068.5-.262.95-.675.95H1.518c-.412 0-.744-.45-.676-.95.365-2.767 2.317-4.884 4.668-4.884h4.053c2.351 0 4.304 2.117 4.668 4.884M7.506 4.167c.272 0 .535.036.789.095v2.726c0 .861.687 1.567 1.544 1.601v.852c0 .447.26.83.636 1.015-.639.967-1.73 1.606-2.969 1.606-1.968 0-3.565-1.608-3.565-3.59v-.716c0-1.982 1.597-3.59 3.565-3.59zM18.395.833c.425 0 .772.346.772.77v5.136c0 .423-.347.769-.772.769h-4.541l-2.516 2.164c-.063.046-.124.069-.194.069-.147 0-.293-.115-.293-.3V7.508h-.948c-.424 0-.771-.346-.771-.77V1.604c0-.424.347-.77.771-.77z"
    />
  </svg>
);
const getTile = (id, t) => {
  if (id === 'howItWorks') {
    return t('help.howItWorks.title');
  }
  if (id === 'howToInstall') {
    return t('help.howToInstall.title');
  }
  if (id === 'terms') {
    return t('help.terms.title');
  }
  if (id === 'support') {
    return t('help.support.title');
  }
};

const getHref = (id, t) => {
  if (id === 'howItWorks') {
    return t('help.howItWorks.link');
  }
  if (id === 'howToInstall') {
    return t('help.howToInstall.link');
  }
  if (id === 'terms') {
    return t('help.terms.link');
  }
  if (id === 'support') {
    return t('help.support.link');
  }
};

const MenuRow = ({ Icon, id }) => {
  const { t } = useTranslation();
  const title = getTile(id, t);
  const href = getHref(id, t);

  return (
    <MenuItem component="a" color="inherit" underline="none" href={href} target="_blank">
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <CustomTypograph variant="body1">{title}</CustomTypograph>
    </MenuItem>
  );
};

const HelpMenu = ({ handleClose }) => (
  <MenuList>
    <MenuRow id="howItWorks" handleClick={handleClose} Icon={HowItWorksIcon} />
    <MenuRow id="howToInstall" handleClick={handleClose} Icon={HowToInstallIcon} />
    <MenuRow id="terms" handleClick={handleClose} Icon={TermsIcon} />
    <MenuRow id="support" handleClick={handleClose} Icon={ContactUsIcon} />
  </MenuList>
);

export default HelpMenu;
