import React from 'react';
import { arrayOf, bool, element, func, number, shape, string } from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';


import DialogButtonActions from './DialogButtonActions';
import useStyles from './useDialogStyle';

const DialogV2 = ({
  title,
  actions,
  dividers,
  buttonsConfig,
  fullScreen,
  isProcessing,
  onlyOneButton,
  onClose,
  padded,
  controledWidth,
  noHorizontalScroll,
  open,
  children,
  progress,
  'data-testid': testId
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ controledWidth, noHorizontalScroll });

  const isRenderActions = actions || (buttonsConfig && Array.isArray(buttonsConfig) && buttonsConfig.length);

  return (
    <Dialog
      open={open}
      data-testid={testId}
      fullScreen={fullScreen && isMobileScreen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={{
        container: classes.container,
        paper: classes.paper
      }}
    >
      <Paper className={classes.paperStyle}>
        <DialogTitle id="responsive-dialog-title">
            {title}
        </DialogTitle>
        { progress && <LinearProgress variant="determinate" value={progress} />}
        <DialogContent className={!padded ? classes.nonPadded : ''} dividers={dividers}>
          {children}
        </DialogContent>
      </Paper>
      {isRenderActions && (
        <DialogActions>
          <DialogButtonActions buttonsConfig={buttonsConfig} actions={actions} isProcessing={isProcessing} onlyOneButton={onlyOneButton} />
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogV2.defaultProps = {
  title: 'DIALOG TITLE',
  fullScreen: true,
  dividers: false,
  padded: true,
  onClose: () => {},
  noHorizontalScroll: false
};

DialogV2.propTypes = {
  isProcessing: bool,
  onlyOneButton: bool,
  noHorizontalScroll: bool,
  open: bool,
  children: func,
  title: string,
  actions: element,
  padded: bool,
  dividers: bool,
  buttonsConfig: arrayOf(
    shape({
      type: string,
      handleClick: func,
      color: string,
      close: bool
    })
  ),
  fullScreen: bool,
  controledWidth: bool,
  onClose: func,
  progress: number,
};

export default DialogV2;
