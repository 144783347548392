import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.colors.white,
    maxWidth: 350,
    minWidth: 300,
    borderRadius: 8
  }
}));

const MenuPopver = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { children, ContentComponent, ...rest } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
        {children}
      </IconButton>

      <Popover
        classes={{
          paper: classes.paper
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...rest}
      >
        {ContentComponent && <ContentComponent handleClose={handleClose} />}

      </Popover>
    </div>
  );
};

MenuPopver.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

MenuPopver.propTypes = {
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  ContentComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

export default MenuPopver;
