import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from '../sagas';
import { env } from '../config/utils/getEnv';

import reducers from './reducer';
import { loadFromSession, saveInSession } from './persistData';


function isDevelopmentMode() {
  return env.NODE_ENV === 'development' || env.REACT_APP_ENV === 'staging';
}

/* eslint-disable no-underscore-dangle */
function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = {
    shopping: {
      ...reducers(undefined, {}).shopping,
      cart: loadFromSession('cart') || []
    }
  };

  const store = createStore(
    reducers,
    persistedReducer,
    compose(
      applyMiddleware(sagaMiddleware),
      isDevelopmentMode() && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );
  /* eslint-enable */

  store.subscribe(() => {
    saveInSession('cart', store.getState().shopping.cart);
  });

  sagaMiddleware.run(sagas);

  return store;
}

export default configureStore;
