import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardActionArea, CardActions, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';

import CountryImageBlock from './CountryImageBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    maxWidth: (props) => props.maxWidth,
    width: '100%'
  },
  fallback: {
    height: (props) => props.imageHeight,
    width: '100%',
    objectFit: 'cover'
  },
  content: {
    padding: theme.spacing(2),
    boxShadow: (props) => (props.hasSeparator ? theme.shadows[21] : 'none')
  },
  actions: {
    padding: theme.spacing(2),
    maxHeight: 54,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const CustomCard = (props) => {
  const classes = useStyles(props);
  const {
    alt,
    imageTitle,
    imageSubtitle,
    backgroundImage,
    fallbackImage,
    actions,
    onClick,
    children,
    clickable,
    ...remaningProps
  } = props;

  const renderImage = () => backgroundImage && <CountryImageBlock {...props} />;

  const renderChildren = () =>
    React.Children.map(
      children,
      (child, index) =>
        child && (
          <div key={`cardSection_${index + 1}`} className={classes.content}>
            {child}
          </div>
        )
    );

  const handleActionArea = () => {
    if (clickable) {
      return (
        <CardActionArea onClick={onClick} component={props.ActionAreaContainer}>
          {renderImage()}
          {renderChildren()}
        </CardActionArea>
      );
    }

    return (
      <CardContent>
        {renderImage()}
        {renderChildren()}
      </CardContent>
    );
  };

  return (
    <Card raised className={classes.root} {...remaningProps}>
      {handleActionArea()}

      {actions && (
        <CardActions>
          <Box display="flex" flex={1} className={classes.actions}>
            {actions}
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

CustomCard.defaultProps = {
  fallbackImage: '',
  alt: 'No title',
  maxWidth: 377,
  clickable: false
};

CustomCard.propTypes = {
  alt: PropTypes.string,
  imageTitle: PropTypes.string,
  imageSubtitle: PropTypes.string,
  backgroundImage: PropTypes.string,
  fallbackImage: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  maxWidth: PropTypes.number,
};

export default CustomCard;
