import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import colors from '../../theme/colors';
import PlanUtils from '../../../utils/plan';

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingLeft: theme.spacing(0.5),
    display:'flex',
    alignItems:'center'
  },
  status: {
    color: props => theme.palette.planStatus[props.status]
  }
}));

const Active = (props) => (
  <div className={props.definedClass}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <path fill={colors.green1} fillRule='evenodd'
            d='M2.815 2.815c2.864-2.864 7.506-2.864 10.37 0 2.864 2.864 2.864 7.506 0 10.37-2.864 2.864-7.506 2.864-10.37 0-2.864-2.864-2.864-7.506 0-10.37zm8.432 1.987L6.59 9.588c-.088.09-.23.09-.318 0l-1.525-1.56c-.176-.179-.461-.178-.636.003l-.314.324c-.175.181-.174.475.003.655l2.489 2.523c.087.09.23.09.317 0l5.595-5.75c.176-.181.176-.473 0-.654l-.318-.327c-.175-.18-.46-.18-.636 0z'/>
    </svg>
  </div>
);

const NotActive = (props) => (
  <div className={props.definedClass}>
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16'>
      <path fill='#0D313F' fillOpacity='.65' fillRule='evenodd'
            d='M7.872 10h1.333V3.333H7.872V10zm0 2.667h1.333v-1.334H7.872v1.334zm.666-11.334C4.858 1.333 1.872 4.32 1.872 8c0 3.68 2.986 6.667 6.666 6.667S15.205 11.68 15.205 8c0-3.68-2.987-6.667-6.667-6.667z'/>
    </svg>
  </div>
);

const SetupEsim = (props) => (
  <div className={props.definedClass}>
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16'>
      <path fill='#ff6600' fillOpacity='.65' fillRule='evenodd'
            d='M7.872 10h1.333V3.333H7.872V10zm0 2.667h1.333v-1.334H7.872v1.334zm.666-11.334C4.858 1.333 1.872 4.32 1.872 8c0 3.68 2.986 6.667 6.666 6.667S15.205 11.68 15.205 8c0-3.68-2.987-6.667-6.667-6.667z'/>
    </svg>
  </div>
);

const getCurrentStatus = ({ status, esimStatus }) => {
  if (!PlanUtils.isStatusActive(status)) return 'notActive';
  if (PlanUtils.isEsimStatusUninstalled(esimStatus)) return 'setupEsim';

  return 'active'
};

const getStatusProps = (status) => {
  switch (status) {
    case 'active':
      return {
        content: 'plan.active',
        icon: Active
      };
    case 'notActive':
      return {
        content: 'plan.inactive',
        icon: NotActive
      };
    case 'setupEsim':
      return {
        content: 'plan.setupEsim',
        icon: SetupEsim
      };
    default:
      return null;
  }
}

const PlanStatusIcon = (props) => {
  const status = getCurrentStatus(props);
  const { t } = useTranslation();

  const classes = useStyles({
    status,
  });

  const statusProps = getStatusProps(status);

  const Icon = statusProps.icon;

  return (
    <Box display='flex' flexDirection='row'>
      <Typography className={classes.status} variant='subtitle2'>
        {t(statusProps.content)}
      </Typography>
      <Icon definedClass={classes.icon} />
    </Box>
  );
};

export default PlanStatusIcon;

PlanStatusIcon.defaultProps = {
  status: '',
  esimStatus: '',
};
