import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '../../theme/theme';

import Flexbox from './Flexbox';
import Type from './Typography';
import Flag from './Flag';

const StyleFlagWithLabel = styled.div`
  width: ${theme.size.swell(18)};
  height: ${theme.size.swell(6)};
  margin: ${theme.size.swell(1)};

  img {
    margin: ${theme.size.swell(1)};
  }
`;

const FlagWithLabel = (props) => (
    <StyleFlagWithLabel>
      <Flexbox justifyContent='flex-start'>
        <Flag code={props.code} />
        {props.typeSize === 's' &&
        <Type.P white={props.white}>{props.label}</Type.P>}
        {props.typeSize === 'm' &&
        <Type.H5 white={props.white}>{props.label}</Type.H5>}
      </Flexbox>
    </StyleFlagWithLabel>
  );

FlagWithLabel.defaultProps = {
  code: 'GB',
  label: 'United Kingdom',
  typeSize: 's',
  white: false,
};

FlagWithLabel.propTypes = {
  code: PropTypes.string,
  label: PropTypes.string,
  typeSize: PropTypes.string,
  white: PropTypes.bool,
};

export default FlagWithLabel;
